<template>
  <div>
    <h2 class="pb-4">{{ title }}</h2>
    <div
      class="pr-3 pl-3"
      style="display: grid; grid-template-columns: 5fr 5fr"
    >
      <div
        style="
          display: grid;
          grid-template-rows: repeat(14, minmax(auto, 2rem));
          grid-template-columns: 4fr 6fr;
        "
      >
        <div class="text-bold releaseRequestGridBorderLeft">ORDER NO.</div>
        <div class="releaseGridBorder">
          {{ release.order }}
        </div>

        <!-- leftForm 수신처 -->
        <div class="text-bold releaseRequestGridBorderLeft">수신처</div>
        <div class="releaseGridBorder">
          <div>
            {{ release.place_forward ? release.place_forward : "" }}
          </div>
        </div>

        <!-- leftForm 입고처 -->
        <div class="text-bold releaseRequestGridBorderLeft">입고처</div>
        <div class="releaseGridBorder">
          <div>
            {{
              release.kind === 17
                ? "자사 매입"
                : release.place_receive
                ? release.place_receive
                : ""
            }}
          </div>
        </div>

        <!-- leftForm 발신인 -->
        <div class="text-bold releaseRequestGridBorderLeft">발신인</div>
        <div class="releaseGridBorder">
          <div>
            {{ release.liaison ? release.liaison : "" }}
          </div>
        </div>

        <!-- leftForm 출고일 -->
        <div class="text-bold releaseRequestGridBorderLeft">출고일</div>
        <div class="releaseGridBorder">
          <div>
            {{ release.date_delivery ? dateFormat(release.date_delivery) : "" }}
          </div>
        </div>

        <!-- leftForm 출고일 -->
        <div class="text-bold releaseRequestGridBorderLeft">ITEM NO</div>
        <div class="releaseGridBorder">
          <div>
            {{ release.item ? release.item : "" }}
          </div>
        </div>

        <!-- leftForm 출고일 -->
        <div class="text-bold releaseRequestGridBorderLeft">매출처</div>
        <div class="releaseGridBorder">
          <div>
            {{ release.place_sales ? release.place_sales : "" }}
          </div>
        </div>

        <!-- leftForm 매출단가 -->
        <div class="text-bold releaseRequestGridBorderLeft">
          {{
            release.currency_sign === "₩" || !release.currency_sign
              ? "매출단가"
              : "매출단가/환율"
          }}
        </div>

        <div class="manufactureGridBorder">
          <div class="pr-2">
            <div
              :class="
                release.currency_sign === '₩' || !release.currency_sign
                  ? 'requestUnitPrice'
                  : 'requestUnitPriceExchange'
              "
            >
              <div v-if="release.sales_unit_price" class="requestPriceOrigin">
                {{
                  ((release.currency_sign ? release.currency_sign : "₩") +
                    (release.sales_unit_price ? release.sales_unit_price : ""))
                    | makeComma
                }}
              </div>

              <div
                class="requestPriceOrigin"
                v-if="
                  release.sales_unit_price &&
                  release.currency_sign !== '₩' &&
                  release.currency_sign
                "
              >
                {{ ("₩" + release.sales.eschamge) | makeComma }}
              </div>
            </div>
          </div>
        </div>

        <div class="text-bold releaseRequestGridBorderLeft">
          Surcharge (컬러 당)
        </div>
        <div class="manufactureGridBorder">
          <div class="requestPriceOrigin pr-2">
            {{
              release.color_surcharge_unit
                ? release.currency_sign + release.color_surcharge_unit
                : ""
            }}
          </div>
        </div>

        <div class="text-bold releaseRequestGridBorderLeft">
          Surcharge (건 당)
        </div>
        <div class="manufactureGridBorder">
          <div class="requestPriceOrigin pr-2">
            {{
              release.surcharge_unit
                ? release.currency_sign + release.surcharge_unit
                : ""
            }}
          </div>
        </div>

        <div
          class="text-bold releaseRequestGridBorderLeft"
          style="grid-column: span 2"
        >
          주의사항
        </div>
        <div
          class="text-bold releaseGridBorderBotoom"
          style="grid-column: span 2; grid-row: span 5"
        >
          <v-textarea
            no-resize
            rows="6"
            dense
            :outlined="false"
            clear-icon="mdi-close-circle"
            hide-details
            v-model="release.precautions"
            @keydown.native="tabMove($event, 8)"
            :ref="`inputTab8`"
            class="lefted-input"
            :tabindex="8"
            disabled
            style="overflow-x: hidden; overflow-y: hidden"
          />
        </div>
      </div>
      <div
        style="
          display: grid;
          grid-template-rows: repeat(14, minmax(auto, 2rem));
          grid-template-columns: 3fr 3fr 4fr;
        "
      >
        <div class="text-bold requestColorListHeader">STYLE</div>
        <div class="text-bold requestColorListHeader">COLOR</div>
        <div class="text-bold requestColorListHeader">수 량</div>
        <div
          style="
            display: grid;
            grid-column: span 3;
            grid-row: span 8;
            overflow-y: overlay;
          "
        >
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
            style="min-width: 100%"
          >
            <div
              v-for="(color, i) in 8"
              :key="color._id"
              style="display: grid; grid-template-columns: 3fr 3fr 4fr"
            >
              <!-- RightColorList 스타일 -->
              <div class="releaseRequestColorListBody">
                <div>
                  {{ release.color_list[i] ? release.color_list[i].style : "" }}
                </div>
              </div>

              <!-- RightColorList 컬러 -->
              <div class="releaseRequestColorListBody">
                <div>
                  {{ release.color_list[i] ? release.color_list[i].name : "" }}
                </div>
              </div>

              <!-- RightColorList 수량 -->
              <div class="originRequestColorListBody pr-2">
                <div>
                  {{
                    release.color_list[i]
                      ? (release.color_list[i].success_quantity
                          ? release.color_list[i].success_quantity
                          : release.color_list[i].quantity
                          ? release.color_list[i].quantity
                          : 0) + release.unit_sign
                      : "" | makeComma
                  }}
                </div>
              </div>
            </div>
          </overlay-scrollbars>
        </div>
        <div class="requestReleaseColorListTot" style="grid-column: span 2">
          TOTAL
        </div>
        <div class="requestReleaseColorListTot">
          {{ (totalQuantity + release.unit_sign) | makeComma }}
        </div>
        <div
          class="text-bold releaseRequestGridBorderRight"
          style="grid-column: span 3"
        >
          비고
        </div>
        <div
          class="text-bold releaseRequestGridBorderRightBottom"
          style="grid-column: span 3; grid-row: span 5"
        >
          <v-textarea
            no-resize
            rows="6"
            dense
            :outlined="false"
            clear-icon="mdi-close-circle"
            hide-details
            v-model="release.remark"
            @keydown.native="tabMove($event, 8)"
            :ref="`inputTab8`"
            class="lefted-input"
            :tabindex="8"
            disabled
            style="overflow-x: hidden; overflow-y: hidden"
          />
        </div>
      </div>

      <div
        class="pt-2"
        style="
          display: grid;
          grid-area: 3/1/3/3;
          grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
          grid-template-rows: repeat(2, minmax(auto, 2rem));
        "
      >
        <div class="releaseCaculateTop">매입수량</div>
        <div class="releaseCaculateTop">금액</div>
        <div class="releaseCaculateTop">염색 Surcharge</div>
        <div class="releaseCaculateTop">건 당 Surcharge</div>
        <div
          class="releaseCaculateTop"
          style="border-right: thin solid rgba(0, 0, 0, 1)"
        >
          금액 합
        </div>

        <!-- 매입 수량 -->
        <div class="releaseCaculateBottom">
          <div>
            {{
              release.isPur && release.purchase.quantity
                ? release.purchase.quantity + release.purchase.unit_sign
                : "" | makeComma
            }}
          </div>
        </div>

        <!-- 매입 단가 -->
        <div class="releaseCaculateBottom">
          {{
            release.isPur && release.purchase.unit_price
              ? release.purchase.currency_sign + release.purchase.unit_price
              : "" | makeComma
          }}
          {{
            release.isPur && release.purchase.eschamge
              ? ` (₩${release.purchase.eschamge}) `
              : "" | makeComma
          }}
        </div>

        <!-- surcharge (제직, 건 당) -->
        <div class="releaseCaculateBottom">
          {{
            release.isPur && release.purchase.color_surcharge_price
              ? release.purchase.currency_sign +
                release.purchase.color_surcharge_price
              : "" | makeComma
          }}
        </div>

        <!-- surcharge (염색, 건 당) -->
        <div class="releaseCaculateBottom">
          <div>
            {{
              release.isPur && release.purchase.surcharge_price
                ? release.purchase.currency_sign +
                  release.purchase.surcharge_price
                : "" | makeComma
            }}
          </div>
        </div>

        <!-- 금액합 -->
        <div
          class="releaseCaculateBottom"
          style="border-right: thin solid rgba(0, 0, 0, 1)"
        >
          <div>
            {{
              release.isPur && release.purchase.total_amount
                ? release.purchase.currency_sign + release.purchase.total_amount
                : "" | makeComma
            }}
            {{
              release.isPur && release.purchase.total_amount
                ? purchaseExchange
                : "" | makeComma
            }}
          </div>
        </div>
      </div>

      <div
        class="pt-2"
        style="
          display: grid;
          grid-area: 4/1/4/3;
          grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
          grid-template-rows: repeat(2, minmax(auto, 2rem));
        "
      >
        <div class="releaseCaculateTop">매출수량</div>
        <div class="releaseCaculateTop">매출단가</div>
        <div class="releaseCaculateTop">컬러 Surcharge</div>
        <div class="releaseCaculateTop">건 당 Surcharge</div>

        <div
          class="releaseCaculateTop"
          style="border-right: thin solid rgba(0, 0, 0, 1)"
        >
          매출금액
        </div>

        <!-- 매출 수량 -->
        <div class="releaseCaculateBottom">
          <div>
            {{
              release.sales && release.sales.quantity
                ? release.sales.quantity + this.release.unit_sign
                : "" | makeComma
            }}
          </div>
        </div>

        <!-- 매출단가 -->
        <div class="releaseCaculateBottom">
          <div>
            {{
              release.sales_unit_price
                ? (release.currency_sign ? release.currency_sign : "₩") +
                  release.sales_unit_price
                : "" | makeComma
            }}
          </div>
        </div>

        <!-- surcharge (컬러) -->
        <div class="releaseCaculateBottom">
          <div>
            {{
              release.color_surcharge_unit
                ? (release.currency_sign ? release.currency_sign : "₩") +
                  release.color_surcharge_unit
                : "" | makeComma
            }}
          </div>
        </div>

        <!-- surcharge (제직) -->
        <div class="releaseCaculateBottom">
          <div>
            {{
              release.surcharge_unit
                ? (release.currency_sign ? release.currency_sign : "₩") +
                  release.surcharge_unit
                : "" | makeComma
            }}
          </div>
        </div>

        <!-- 매출금액 -->
        <div
          class="releaseCaculateBottom"
          style="border-right: thin solid rgba(0, 0, 0, 1)"
        >
          <div class="">
            {{
              this.release.sales && this.release.sales.total_amount
                ? (this.release.currency_sign
                    ? this.release.currency_sign
                    : "₩") + this.release.sales.total_amount
                : "" | makeComma
            }}
            {{ this.release.sales ? salesExchange : "" | makeComma }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      requestData: "getRequestForm",
    }),
    release() {
      return this.requestData.requestDoc;
    },

    salesExchange() {
      return this.release.sales.eschamge_price
        ? `  (₩${this.release.sales.eschamge_price})`
        : "";
    },

    originSalesExchange() {
      const eschamge =
        this.release.origin.currency_sign !== "₩"
          ? this.release.origin.sales.eschamge
          : 0;

      const total_amount = this.release.origin.sales.total_amount
        ? this.release.origin.sales.total_amount
        : 0;
      const eschamge_amount = eschamge * total_amount;

      return eschamge_amount ? `  (₩${eschamge_amount})` : "";
    },

    purchaseExchange() {
      const eschamge = this.release.purchase.eschamge
        ? this.release.purchase.eschamge
        : 0;
      const total_amount = this.release.purchase.total_amount
        ? this.release.purchase.total_amount
        : 0;
      const eschamge_amount = eschamge * total_amount;

      return eschamge_amount ? `  (₩${eschamge_amount})` : "";
    },

    originPurchaseExchange() {
      const eschamge = this.release.origin.purchase.eschamge
        ? this.release.origin.purchase.eschamge
        : 0;
      const total_amount = this.release.origin.purchase.total_amount
        ? this.release.origin.purchase.total_amount
        : 0;
      const eschamge_amount = eschamge * total_amount;

      return eschamge_amount ? `  (₩${eschamge_amount})` : "";
    },

    totalQuantity() {
      return this.release.color_list.length
        ? this.release.color_list.reduce(
            (a, c) =>
              a +
              +(c.name
                ? c.success_quantity
                  ? +c.success_quantity
                  : c.quantity
                  ? +c.quantity
                  : 0
                : 0),
            0,
          )
        : 0;
    },
    title() {
      return `${
        this.release.kind === 8
          ? "매입 출고의뢰서"
          : this.release.kind === 9
          ? "매출 출고의뢰서"
          : this.release.kind === 10
          ? "검사 의뢰서"
          : this.release.kind === 15
          ? "매입/매출 출고의뢰서"
          : this.release.kind === 16
          ? "출고의뢰서"
          : this.release.kind === 17
          ? "재고 매입"
          : this.release.kind === 19
          ? "생지 매입"
          : "선매입"
      }(${
        this.release.status === "20"
          ? "승인"
          : this.release.status === "50"
          ? "수정"
          : this.release.status === "40"
          ? "승인"
          : "취소"
      })`;
    },

    origin() {
      return this.release.origin ? this.release.origin : { color_list: [] };
    },
  },
  methods: {
    colorCompare(type, i) {
      const requestColor =
        this.release.color_list[i] && this.release.color_list[i][type];
      const originColor =
        this.release.origin.color_list[i] &&
        this.release.origin.color_list[i][type];

      return (originColor !== undefined || requestColor !== undefined) &&
        originColor !== requestColor
        ? true
        : false;
    },
  },
};
</script>

<style></style>
