<template>
  <div>
    <div class="requestListGrid headerGreyBorder">
      <div class="reqeustListGridHeader" v-for="col in header" :key="col.value">
        <div v-if="col.value === 'view'" class="reqeustListGridHeaderRight">
          {{ col.text }}
        </div>
        <div v-else class="reqeustListGridHeaderBody">
          {{ col.text }}
        </div>
      </div>
    </div>

    <overlay-scrollbars
      :options="{
        overflowBehavior: {
          x: 'hidden',
        },
      }"
    >
      <div style="width: 100%; max-height: 30vh">
        <template v-if="requestList.length">
          <div
            class="requestListGrid bodyGreyBorder"
            v-for="(item, i) in requestList"
            :key="i"
          >
            <div v-for="col in header" :key="col.value">
              <div class="reqeustListGridBody requestFlexTable">
                <div
                  v-if="col.value === 'view'"
                  class="reqeustListGridBodyRight"
                >
                  <a @click="viewApproval(item)">보기</a>
                </div>
                <div v-else class="reqeustListGridBodyLeft">
                  {{ item[col.value] ? item[col.value] : "" }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="bodyGreyBorder requestFlexTable reqeustListGridBodyRight">
            요청목록이 존재하지 않습니다.
          </div>
        </template>
      </div>
    </overlay-scrollbars>
  </div>
</template>
<script>
import ApiActionName from "../common/ApiActionName";

export default {
  data() {
    return {
      header: [
        {
          text: "날짜",
          value: "date",
          width: "30px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "오더",
          value: "order",
          width: "30px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "발주처",
          value: "buyer",
          width: "45px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "문서",
          value: "kind",
          width: "20px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "담당자",
          value: "liaison",
          width: "20px",
          align: "center",
          class: "userRequestRight",
        },
        {
          text: "상태",
          value: "status",
          width: "30px",
          align: "center",
          class: "userRequestRight",
        },
        {
          text: "승인 여부",
          value: "confirm_status",
          width: "20px",
          align: "center",
          class: "userRequestRight",
          filterable: false,
        },
        {
          text: "확인",
          value: "view",
          width: "20px",
          align: "center",
          class: "userRequestRight",
          filterable: false,
        },
      ],
    };
  },
  computed: {
    filterHeader() {
      return {
        main: this.$store.state.mainHeader,
        order: this.$store.state.orderHeader,
        production: this.$store.state.productionHeader,
        purchasecalculate: this.$store.state.purCacheader,
        purchasedeadline: this.$store.state.purDedheader,
        salescalculate: this.$store.state.salesCacheader,
        salesdeadline: this.$store.state.saelsDedheader,
        purchaseVat: this.$store.state.receiveHeader,
        salesVat: this.$store.state.saelsreceiveHeader,
        claim: this.$store.state.claimHeader,
        endClaim: this.$store.state.endClaimHeader,
        stock: this.$store.state.stockHeader,
      };
    },
  },
  props: ["requestList"],
  methods: {
    async viewApproval(item) {
      const requestType = this.$store.state.requestType;

      if (this.$store.state.auth_grade >= 3) {
        if (requestType === "complete") {
          this.$store.dispatch("SET_REQUEST_FORM_DATA", {
            approvalId: item.approvalId,
            name: item.name,
            id: item.id,
            order: item.order,
          });
          this.$store.commit("setRequestFormDialog");
        }

        if (requestType === "normal") {
          //오더페이지 이동
          if (item.name === "order") {
            this.$store.dispatch(
              "SET_FORM",
              JSON.parse(JSON.stringify(item.order)),
            );
          }
          //생산페이지 이동
          if (item.name === "release" || item.name === "manufacture") {
            this.$store.dispatch("PRODUCTION_DIALOG_INIT");
            await this.$store.dispatch("PRODUCTION_ORDER_CHANGE", item.order);
            if (item.id)
              await this.$store.dispatch("GET_ATTACHMENTLIST", item.id);
            const order = this.$store.state.productionOrderForm;
            const arrayList =
              item.name === "release" ? order.release : order.manufacture;
            const targetForm = arrayList.find((data) => data.id === item.id);
            const prgoress = order.progress.find((data) => data.id === item.id);
            const targetFormIndex = order.progress.findIndex(
              (data) => data.id === item.id,
            );
            if (this.requestDocCheck(targetForm.kind)) {
              this.$store.commit("setProductionProgress", {
                ...prgoress,
                index: targetFormIndex,
                isIndex: !targetFormIndex,
              });
              this.$store.dispatch("PRODUCTION_OPENDIALOG");
            }
          }

          this.movePage(item);
        }
      }

      if (this.$store.state.auth_grade <= 2) {
        this.$store.dispatch("SET_REQUEST_FORM_DATA", {
          approvalId: item.approvalId,
          name: item.name,
          id: item.id,
          order: item.order,
        });
        this.$store.commit("setRequestFormDialog");
      }
    },
    movePage(item) {
      if (item.order) {
        const names = ApiActionName[item.route];

        const status =
          this.$route.name === "order" && this.$store.state.auth_grade >= 2
            ? names.managerStatus
            : names.status;
        const param = {
          multi: JSON.stringify([{ field: "order", params: [item.order] }]),
          field: "order",
          sort: 1,
          status: status,
          pageType: names.pageType,

          isEndCase: names.isEndCase,
          kind: names.kind ? names.kind : "",
        };
        const paramData = [
          {
            field: "order",
            params: [item.order],
          },
        ];

        this.$store.dispatch("CALL_SEARCH_API", {
          paramName: names.paramName,
          totalApiName: names.totalApiName,
          searchApiName: names.searchApiName,
          param: paramData,
          totalData: {
            param: param,
            commitName: names.totalCommitName,
          },
          searchData: {
            param: param,
            commitName: names.searchCommitName,
          },
        });
        this.initHeader(paramData);

        if (this.$route.name !== item.route) {
          this.$router.push({ name: item.route }).then((status) => {
            this.initHeader(paramData);
          });
        }
      }
    },
    initHeader(param) {
      this.filterHeader[this.$route.name].map((head) => {
        head.checked = !!param.find((param) => param.field === head.value);
        return head;
      });
    },
  },
};
</script>
<style lang="scss" src="@/components/pages/common/scss/request.scss" />
