import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      requestData: "getRequestForm",
    }),

    release() {
      return this.requestData.requestDoc;
    },

    isModify() {
      return this.requestData.isModify;
    },

    origin() {
      return this.requestData.isModify
        ? this.requestData.originDoc
        : { color_list: [] };
    },

    receivePlace() {
      return this.release
        ? this.release.kind === 17
          ? "자사 매입"
          : this.release.place_receive
        : "";
    },

    currencyName() {
      return this.release.currency_sign === "₩" || !this.release.currency_sign
        ? "매출단가"
        : "매출단가/환율";
    },

    salesExchange() {
      return this.release.sales.eschamge_price
        ? `  (₩${this.release.sales.eschamge_price})`
        : "";
    },

    purchaseExchange() {
      const eschamge = this.release.purchase.eschamge
        ? this.release.purchase.eschamge
        : 0;
      const total_amount = this.release.purchase.total_amount
        ? this.release.purchase.total_amount
        : 0;
      const eschamge_amount = eschamge * total_amount;

      return eschamge_amount ? `  (₩${eschamge_amount})` : "";
    },

    originPurchaseExchange() {
      const eschamge = this.origin.purchase.eschamge
        ? this.origin.purchase.eschamge
        : 0;
      const total_amount = this.origin.purchase.total_amount
        ? this.origin.purchase.total_amount
        : 0;
      const eschamge_amount = eschamge * total_amount;

      return eschamge_amount ? `  (₩${eschamge_amount})` : "";
    },

    totalQuantity() {
      return this.release.color_list.length
        ? this.release.color_list.reduce(
            (a, c) =>
              a +
              +(c.name
                ? c.success_quantity
                  ? +c.success_quantity
                  : c.quantity
                  ? +c.quantity
                  : 0
                : 0),
            0,
          )
        : 0;
    },
    title() {
      return `${
        this.release.kind === 8
          ? "매입 출고의뢰서"
          : this.release.kind === 9
          ? "매출 출고의뢰서"
          : this.release.kind === 10
          ? "검사 의뢰서"
          : this.release.kind === 15
          ? "매입/매출 출고의뢰서"
          : this.release.kind === 16
          ? "출고의뢰서"
          : this.release.kind === 17
          ? "재고 매입"
          : this.release.kind === 19
          ? "생지 매입"
          : "선매입"
      }(${
        this.release.status === "20"
          ? "승인"
          : this.release.status === "50"
          ? "수정"
          : "취소"
      } 요청)`;
    },
  },

  data() {
    return {
      isOrigin: true,
      priceCompares: ["sales_unit_price", "eschamge", "currency_sign"],
    };
  },
  methods: {
    // 전달 받은 요소값 비교
    isValueComparison(key) {
      let isComparsion;
      if (Array.isArray(key)) {
        for (let i = 0; i < key.length; i++) {
          const compares = key[i];
          isComparsion = this.release[compares] !== this.origin[compares];
          if (isComparsion) break;
        }
      } else isComparsion = this.release[key] !== this.origin[key];
      return isComparsion;
    },

    // 수정 요청 데이터와 원본 데이터를 비교하여 수정 되었다면 true 아니라면 false 반환
    // * isOrigin 데이터가 true 일때 ture 아니면 false
    isComparison(key) {
      return this.isModify && this.isValueComparison(key) && this.isOrigin;
    },

    // 전달 받은 값에 수정 여부 에 따라 reqModifyTxtColor 또는 '' 를 반환한다.
    classNameReqModifyTxtColor(isCom) {
      return isCom && this.isModify ? "reqModifyTxtColor" : "";
    },

    //수정 요청 데이터 와 원본 데이터를 비교하여 수정 된 데이터라면 originText 아니면라면 '' 를 반환한다.
    classNameisComparison(key) {
      return this.isComparison(key) ? "originText" : "";
    },

    //수정 요청 데이터 와 원본 데이터를 비교하여 수전된 데이터라면 originText 아니라면 '' 를 반환한다.
    numTypeClassNameisCompariso(key) {
      return this.isComparison(key)
        ? "originTextNumType numTextLeftPadding"
        : "numTextLeftPadding";
    },

    // 키값을 전달 class name 을 생성 한뒤 합쳐 반환
    reqClassName(key, isNum) {
      const className = isNum
        ? this.numTypeClassNameisCompariso(key)
        : this.classNameisComparison(key);
      const colorClassName = this.classNameReqModifyTxtColor(
        this.isValueComparison(key),
      );

      return `${className} ${colorClassName}`;
    },

    // 화폐 단위를 전달 받아 원화라면 ture 아니라면 false 를 반환 하는 함수
    //* 홤폐 단위가 없더라도 ture => 레거시 데이터에 화폐 단위 가 원화 라면 단위 데이터를 저장하지 않은 경우가 있음
    isWon(unit) {
      return !unit || unit === "₩";
    },

    // 화폐단위 에따라 class 명을 반환 하는 함수
    unitPriceClassName(unit) {
      return this.isWon(unit) ? "requestUnitPrice" : "requestUnitPriceExchange";
    },

    // 컬러 리스트의 객체의 key 를 전달 받아 수정 된 데이터인지 확인 하여 ture false 반환 하는 함수
    isOriginColor(type, i) {
      const requestColor =
        this.release.color_list[i] && this.release.color_list[i][type];
      const originColor =
        this.origin.color_list[i] && this.origin.color_list[i][type];

      return requestColor !== originColor;
    },

    // 컬러 리스트의 isModify, isOrigin, isComparison 확인 하여 ture false 를 반환 하는 함수
    isColorComparison(type, i) {
      return this.isOriginColor(type, i) && this.isModify && this.isOrigin;
    },

    // 컬러 리스트의 객체의 key 를 전달 받아 수정 된 데이터인지 여부를 파악한 후,
    // ture 라면 class name 을 false 라면 빈값을 반환 한다.
    colorClassNameComparison(type, i) {
      let className = "";

      if (this.isColorComparison(type, i))
        className = type === "name" ? "originText" : "originTextNumType";

      const colorClassName = this.classNameReqModifyTxtColor(
        this.isOriginColor(type, i),
      );

      return `${className} ${colorClassName}`;
    },

    //원본 데이터와 수정 데이터를 비교하는 함수
    isOriginCalculate(type, key) {
      const requestCalcul = this.release[type] && this.release[type][key];
      const originCalcul = this.origin[type] && this.origin[type][key];

      return requestCalcul !== originCalcul;
    },

    // sales, purch 하위 데이터를 비교 하는 함수
    isCalculateComparison(type, key) {
      return (
        this.isOriginCalculate(type, key) && this.isModify && this.isOrigin
      );
    },

    calculateClassName(type, key) {
      const className = this.isCalculateComparison(type, key)
        ? "originTextNumType"
        : "";

      const colorClassName = this.classNameReqModifyTxtColor(
        this.isOriginCalculate(type, key),
      );

      return `${className} ${colorClassName}`;
    },
  },
};
