import header from "@/assets/table/request/requestListHeader";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      header,
    };
  },
  computed: {
    ...mapGetters({
      requestSetList: "getRequestList",
    }),
    bodyData() {
      return 1;
    },
  },
};
