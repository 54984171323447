<template>
  <div class="text-center align-center">
    <v-tooltip max-width="280" color="gray" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="$emit('click')"
          :disabled="disabled"
          class="elevation-0 font-weight-black topGrayButton"
          color="grey lighten-2 "
          height="30"
          v-on="on"
          v-bind="attrs"
        >
          <slot />
        </v-btn>
      </template>
      <span class="text-overflow">
        <v-icon class="mb-1" size="25" color="orange">mdi-information</v-icon>
        {{ Tooltip }}
      </span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: ["disabled", "Tooltip"],
};
</script>
