<template>
  <v-data-table
    :headers="headers"
    :items="$store.state.requestList"
    item-key="_id"
    :items-per-page="-1"
    disable-sort
    hide-default-footer
  >
    <template v-slot:[`body`]="{ items }">
      <tbody v-if="items.length">
        <tr v-for="item in items" :key="item._id" class="text-center">
          <td
            class="userRequest pl-0 pr-0"
            style="font-size: 11px; height: 40px"
          >
            {{ statusKind(item) }}
          </td>
          <td
            class="userRequest pl-0 pr-0"
            style="font-size: 11px; height: 40px"
          >
            {{ item.order }}
          </td>
          <td
            class="userRequest pl-0 pr-0"
            style="font-size: 11px; height: 40px"
          >
            {{ item.order ? docKind(item.name) : "" }}
          </td>

          <td
            class="userRequestRight pl-0 pr-0"
            style="font-size: 11px; height: 40px"
          >
            {{ item.memo }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr style="height: 80px">
          <td class="userRequestRight text-center" colspan="6">
            요청중인 문서가 없습니다.
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "상태",
          value: "status",
          width: "30px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "오더",
          value: "order",
          width: "30px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "문서",
          value: "kind",
          width: "30px",
          align: "center",
          class: "userRequestHeader",
        },
        {
          text: "비고",
          value: "memo",
          width: "80px",
          align: "center",
          class: "userRequestHeaderRight",
        },
      ],
    };
  },
  computed: {
    dialog() {
      return this.$store.state.userRequesDialog;
    },
  },
  methods: {
    close() {
      this.$store.commit("setUserRequestDialog");
    },
    statusKind(item) {
      const status = item.status;
      const kind = item.name;
      return status === "20"
        ? "승인요청"
        : status === "50"
        ? "수정요청"
        : status === "60"
        ? "마감요청"
        : kind !== "endClaim" &&
          kind !== "claim" &&
          (status === "90" || status === "10")
        ? "취소요청"
        : (kind === "endClaim" || kind === "claim") && status === "10"
        ? "삭제요청"
        : (kind === "endClaim" || kind === "claim") && status === "90"
        ? "취소요청"
        : "";
    },

    docKind(kind) {
      return kind === "order"
        ? "오더"
        : kind === "release" || kind === "sales"
        ? "출고"
        : kind === "manufacture"
        ? "가공"
        : kind === "claim"
        ? "클레임"
        : kind === "endClaim"
        ? "클레임"
        : kind === "purchasecalculate"
        ? "매입정산"
        : kind === "salescalculate"
        ? "매출정산"
        : kind === "stock"
        ? "재고"
        : kind === "useStock"
        ? "사용재고"
        : kind === "deleteStock"
        ? "사용재고 취소"
        : "매입정산 취소";
    },
  },
};
</script>
<style lang="scss" src="@/components/pages/common/scss/request.scss" />
