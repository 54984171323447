<template>
  <div class="pl-4 pr-4">
    <div style="font-weight: bolder; font-size: 20px" class="pb-2">
      {{ title }}
    </div>
    <div class="d-flex">
      <div>
        <div class="claimRequestsubTitle">
          오더 :
          <span
            v-if="
              origin &&
              claim.order !== origin.order &&
              claim.requestStatus === '50'
            "
          >
            {{ `${origin ? origin.order : "미선택"}` }}
            <v-icon color="red" dense>mdi-arrow-right-thick</v-icon>
          </span>
          <span
            :class="
              origin &&
              claim.order !== origin.order &&
              claim.requestStatus === '50'
                ? 'red--text'
                : ''
            "
          >
            {{ `${claim.order ? claim.order : "미선택"}` }}
          </span>
        </div>
        <div class="claimRequestsubTitle pb-4">
          담당자 :
          <span
            v-if="
              origin &&
              claim.liaison !== origin.liaison &&
              claim.requestStatus === '50'
            "
          >
            {{ `${origin.liaison ? origin.liaison : "미선택"}` }}
            <v-icon color="red" dense>mdi-arrow-right-thick</v-icon>
          </span>
          <span
            :class="
              origin &&
              claim.liaison !== origin.liaison &&
              claim.requestStatus === '50'
                ? 'red--text'
                : ''
            "
          >
            {{ `${claim.liaison ? claim.liaison : "미선택"}` }}
          </span>
        </div>
      </div>
      <div style="margin-left: auto">
        <v-checkbox
          v-if="claim.requestStatus === '50'"
          @click="claim.isOrigin = !claim.isOrigin"
          hide-details
        >
          <template v-slot:label>
            <span class="checkboxLabel">기존 데이터만 보기</span>
          </template>
        </v-checkbox>
      </div>
    </div>
    <div class="requestClaimBodyGrid">
      <div>
        <div
          class="claimAreaTitle font-weight-bold"
          :class="claim.type === 'arise' ? 'red--text ' : ''"
        >
          발생
        </div>
        <div class="claimRequestGrid">
          <div class="claimReuqestBody">월</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  claim.arise_month !== origin.arise_month &&
                  origin.arise_month &&
                  origin.arise_id &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.arise_month ? origin.arise_month : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.arise_month !== origin.arise_month &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.arise_month ? claim.arise_month : "" }}
            </div>
          </div>
          <div class="claimReuqestBody">구분</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.arise_type &&
                  claim.arise_type !== origin.arise_type &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.arise_type ? origin.arise_type : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.arise_type !== origin.arise_type &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.arise_type ? claim.arise_type : "" }}
            </div>
          </div>
          <div class="claimReuqestBody">업체명</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.arise_client &&
                  claim.arise_client !== origin.arise_client &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.arise_client ? origin.arise_client : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.arise_client !== origin.arise_client &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.arise_client ? claim.arise_client : "" }}
            </div>
          </div>
          <div class="claimReuqestBody">금액</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="requestClaimPrice originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.arise_price &&
                  (claim.arise_eschamge !== origin.arise_eschamge ||
                    claim.arise_price !== origin.arise_price) &&
                  claim.requestStatus === '50'
                "
              >
                <div
                  class="requestClaimPrice"
                  v-if="
                    claim.isOrigin &&
                    origin.arise_eschamge &&
                    claim.arise_eschamge !== origin.arise_eschamge &&
                    claim.requestStatus === '50'
                  "
                >
                  {{
                    origin.arise_eschamge
                      ? `${origin.arise_currency_sign}${origin.arise_eschamge}`
                      : ""
                  }}
                </div>
                <div
                  class="requestClaimPrice"
                  v-if="
                    claim.isOrigin &&
                    origin.arise_price &&
                    claim.arise_price !== origin.arise_price &&
                    claim.requestStatus === '50'
                  "
                >
                  {{
                    origin.arise_price
                      ? `${origin.arise_currency_sign}${origin.arise_price}`
                      : ""
                  }}
                </div>
              </div>
            </template>
            <div class="requestClaimPrice">
              <div
                class="requestClaimPrice"
                :class="
                  origin &&
                  claim.arise_eschamge !== origin.arise_eschamge &&
                  claim.requestStatus === '50'
                    ? 'red--text originText'
                    : ''
                "
                v-if="claim.arise_eschamge"
              >
                {{
                  claim.arise_eschamge
                    ? `₩${claim.arise_eschamge}`
                    : "" | makeComma
                }}
              </div>
              <div
                class="requestClaimPrice"
                :class="
                  origin &&
                  claim.arise_price !== origin.arise_price &&
                  claim.requestStatus === '50'
                    ? 'red--text originText'
                    : ''
                "
              >
                {{
                  claim.arise_price
                    ? `${claim.arise_currency_sign}${claim.arise_price}`
                    : "" | makeComma
                }}
              </div>
            </div>
          </div>
          <div class="claimReuqestBody">전가</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.arise_passtype &&
                  claim.arise_passtype !== origin.arise_passtype &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.arise_passtype ? origin.arise_passtype : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.arise_passtype !== origin.arise_passtype &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.arise_passtype ? claim.arise_passtype : "" }}
            </div>
          </div>
          <div class="claimReuqestBody">내용</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.arise_detail &&
                  claim.arise_detail !== origin.arise_detail &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.arise_detail ? origin.arise_detail : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.arise_detail !== origin.arise_detail &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.arise_detail ? claim.arise_detail : "" }}
            </div>
          </div>
          <div class="claimReuqestBodyBottom">상태</div>
          <div class="claimReuqestBodyBottomRight">
            <div>
              {{ origin ? origin.arise_status : "" }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          class="claimAreaTitle font-weight-bold"
          :class="claim.type === 'pass' ? 'red--text ' : ''"
        >
          전가
        </div>
        <div class="claimRequestGrid">
          <div class="claimReuqestBody">월</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.pass_month &&
                  origin.pass_id &&
                  claim.pass_month !== origin.pass_month &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.pass_month ? origin.pass_month : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.pass_month !== origin.pass_month &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.pass_month ? claim.pass_month : "" }}
            </div>
          </div>
          <div class="claimReuqestBody">구분</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.pass_type &&
                  claim.pass_type !== origin.pass_type &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.pass_type ? origin.pass_type : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.pass_type !== origin.pass_type &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.pass_type ? claim.pass_type : "" }}
            </div>
          </div>
          <div class="claimReuqestBody">업체명</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.pass_client &&
                  claim.pass_client !== origin.pass_client &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.pass_client ? origin.pass_client : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.pass_client !== origin.pass_client &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.pass_client ? claim.pass_client : "" }}
            </div>
          </div>
          <div class="claimReuqestBody">금액</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="requestClaimPrice originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.pass_price &&
                  (claim.pass_eschamge !== origin.pass_eschamge ||
                    claim.pass_price !== origin.pass_price) &&
                  claim.requestStatus === '50'
                "
              >
                <div
                  class="requestClaimPrice"
                  v-if="
                    claim.isOrigin &&
                    origin.pass_eschamge &&
                    claim.pass_eschamge !== origin.pass_eschamge &&
                    claim.requestStatus === '50'
                  "
                >
                  {{
                    origin.pass_eschamge
                      ? `${origin.pass_currency_sign}${origin.pass_eschamge}`
                      : ""
                  }}
                </div>
                <div
                  class="requestClaimPrice"
                  v-if="
                    claim.isOrigin &&
                    origin.pass_price &&
                    claim.pass_price !== origin.pass_price &&
                    claim.requestStatus === '50'
                  "
                >
                  {{
                    origin.pass_price
                      ? `${origin.pass_currency_sign}${origin.pass_price}`
                      : ""
                  }}
                </div>
              </div>
            </template>
            <div class="requestClaimPrice">
              <div
                class="requestClaimPrice"
                :class="
                  origin &&
                  claim.pass_eschamge !== origin.pass_eschamge &&
                  claim.requestStatus === '50'
                    ? 'red--text originText'
                    : 'originText'
                "
                v-if="claim.pass_eschamge"
              >
                {{
                  claim.pass_eschamge
                    ? `₩${claim.pass_eschamge}`
                    : "" | makeComma
                }}
              </div>
              <div
                class="requestClaimPrice"
                :class="
                  origin &&
                  claim.pass_price !== origin.pass_price &&
                  claim.requestStatus === '50'
                    ? 'red--text originText'
                    : 'originText'
                "
              >
                {{
                  claim.pass_price
                    ? `${claim.pass_currency_sign}${claim.pass_price}`
                    : "" | makeComma
                }}
              </div>
            </div>
          </div>

          <div class="claimReuqestBody">내용</div>
          <div class="claimReuqestBodyRight">
            <template v-if="origin">
              <div
                class="originTextRight"
                v-if="
                  claim.isOrigin &&
                  origin.pass_detail &&
                  claim.pass_detail !== origin.pass_detail &&
                  claim.requestStatus === '50'
                "
              >
                {{ origin.pass_detail ? origin.pass_detail : "" }}
              </div>
            </template>
            <div
              :class="
                origin &&
                claim.pass_detail !== origin.pass_detail &&
                claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'
              "
            >
              {{ claim.pass_detail ? claim.pass_detail : "" }}
            </div>
          </div>
          <div class="claimReuqestBodyBottom">상태</div>
          <div class="claimReuqestBodyBottomRight">
            <div>
              {{ origin ? origin.pass_status : "" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters({
      claim: "getRequestForm",
    }),
    origin() {
      return this.claim.origin;
    },
    title() {
      const status = this.claim.requestStatus;

      return `클레임 (${
        status === "20"
          ? "승인"
          : status === "50"
          ? "수정"
          : status === "10"
          ? "삭제"
          : status === "90"
          ? "취소"
          : status === "40"
          ? "승인"
          : "마감"
      })`;
    },
  },
  methods: {
    processKind(kind) {
      return kind === 1 ? "발생" : "전가";
    },
    processStatus(status) {
      return status === 1 ? "발행 예정" : status === 2 ? "매입" : "매출";
    },
    clientName(id) {
      const client = this.$store.state.clientList.find((x) => x._id === id);
      return client ? client.name : "";
    },
  },
};
</script>

<style />
