var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1500"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.$store.state.requestFormDialog),callback:function ($$v) {_vm.$set(_vm.$store.state, "requestFormDialog", $$v)},expression:"$store.state.requestFormDialog"}},[_c('v-card',{staticClass:"pr-0 pl-0"},[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"grey lighten-2",staticStyle:{"height":"50px","width":"100%","border-bottom":"thin solid rgba(0, 0, 0, 0.1)","display":"flex"}},[_c('div',{staticClass:"d-flex align-center pl-4",staticStyle:{"margin-right":"auto","width":"100%"}},[_c('h2',[_vm._v("요청이력")])]),_c('div',{staticStyle:{"margin-left":"auto","padding-top":"5px","padding-right":"10px"}},[_c('v-icon',{attrs:{"color":"red","large":""},on:{"click":_vm.close}},[_vm._v(" mdi-close ")])],1)]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"pt-4 pl-3",staticStyle:{"width":"35%"}},[_c('div',{staticClass:"requestListGrid headerGreyBorder"},_vm._l((_vm.header),function(col){return _c('div',{key:col.value,staticClass:"reqeustListGridHeader"},[(col.value === 'view')?_c('div',{staticClass:"reqeustListGridHeaderRight"},[_vm._v(" "+_vm._s(col.text)+" ")]):_c('div',{staticClass:"reqeustListGridHeaderBody"},[_vm._v(" "+_vm._s(col.text)+" ")])])}),0),_c('overlay-scrollbars',{attrs:{"options":{
              overflowBehavior: {
                x: 'hidden',
              },
            }}},[_c('div',{staticStyle:{"width":"100%","max-height":"60vh"}},[(_vm.requestList.length)?_vm._l((_vm.requestList),function(item,i){return _c('div',{key:i,staticClass:"requestListGrid bodyGreyBorder",class:{
                    selectedRow: item.approvalId === _vm.requestForm.approvalId,
                  },on:{"click":function($event){return _vm.setForm(item)}}},_vm._l((_vm.header),function(col){return _c('div',{key:col.value},[_c('div',{staticClass:"reqeustListGridBody requestFlexTable"},[(col.value === 'view')?_c('div',{staticClass:"reqeustListGridBodyRight"},[_vm._v(" "+_vm._s(item.confirm_status && item.confirm_status === true ? "승인" : item.confirm_status === false ? "반려" : "")+" ")]):_c('div',{staticClass:"reqeustListGridBodyLeft"},[_vm._v(" "+_vm._s(item[col.value] ? item[col.value] : "")+" ")])])])}),0)}):_vm._e()],2)])],1),_c('div',{staticStyle:{"min-height":"calc(100vh - 200px)","padding-top":"10px","padding-left":"10px","width":"100%"}},[(_vm.requestForm.name === 'manufacture')?_c('manufactureForm'):_vm._e(),(_vm.requestForm.name === 'release')?_c('releaseForm'):_vm._e(),(_vm.requestForm.name === 'order')?_c('orderForm'):_vm._e(),(
              _vm.requestForm.name === 'claim' || _vm.requestForm.name === 'endClaim'
            )?_c('claimForm'):_vm._e(),(
              _vm.requestForm.name === 'purchasecalculate' ||
              _vm.requestForm.name === 'purchasedeadline' ||
              _vm.requestForm.name === 'salescalculate' ||
              _vm.requestForm.name === 'salesdeadline'
            )?_c('calculateForm'):_vm._e(),(_vm.requestForm.name === 'claim')?_c('stockForm'):_vm._e()],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }