var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-4 pr-4"},[_c('div',{staticClass:"pb-2",staticStyle:{"font-weight":"bolder","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"d-flex"},[_c('div',[_c('div',{staticClass:"claimRequestsubTitle"},[_vm._v(" 오더 : "),(
            _vm.origin &&
            _vm.claim.order !== _vm.origin.order &&
            _vm.claim.requestStatus === '50'
          )?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.origin ? _vm.origin.order : "미선택")))+" "),_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e(),_c('span',{class:_vm.origin &&
            _vm.claim.order !== _vm.origin.order &&
            _vm.claim.requestStatus === '50'
              ? 'red--text'
              : ''},[_vm._v(" "+_vm._s(("" + (_vm.claim.order ? _vm.claim.order : "미선택")))+" ")])]),_c('div',{staticClass:"claimRequestsubTitle pb-4"},[_vm._v(" 담당자 : "),(
            _vm.origin &&
            _vm.claim.liaison !== _vm.origin.liaison &&
            _vm.claim.requestStatus === '50'
          )?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.origin.liaison ? _vm.origin.liaison : "미선택")))+" "),_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-arrow-right-thick")])],1):_vm._e(),_c('span',{class:_vm.origin &&
            _vm.claim.liaison !== _vm.origin.liaison &&
            _vm.claim.requestStatus === '50'
              ? 'red--text'
              : ''},[_vm._v(" "+_vm._s(("" + (_vm.claim.liaison ? _vm.claim.liaison : "미선택")))+" ")])])]),_c('div',{staticStyle:{"margin-left":"auto"}},[(_vm.claim.requestStatus === '50')?_c('v-checkbox',{attrs:{"hide-details":""},on:{"click":function($event){_vm.claim.isOrigin = !_vm.claim.isOrigin}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"checkboxLabel"},[_vm._v("기존 데이터만 보기")])]},proxy:true}],null,false,2560933483)}):_vm._e()],1)]),_c('div',{staticClass:"requestClaimBodyGrid"},[_c('div',[_c('div',{staticClass:"claimAreaTitle font-weight-bold",class:_vm.claim.type === 'arise' ? 'red--text ' : ''},[_vm._v(" 발생 ")]),_c('div',{staticClass:"claimRequestGrid"},[_c('div',{staticClass:"claimReuqestBody"},[_vm._v("월")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.claim.arise_month !== _vm.origin.arise_month &&
                _vm.origin.arise_month &&
                _vm.origin.arise_id &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.arise_month ? _vm.origin.arise_month : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.arise_month !== _vm.origin.arise_month &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.arise_month ? _vm.claim.arise_month : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("구분")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.arise_type &&
                _vm.claim.arise_type !== _vm.origin.arise_type &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.arise_type ? _vm.origin.arise_type : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.arise_type !== _vm.origin.arise_type &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.arise_type ? _vm.claim.arise_type : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("업체명")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.arise_client &&
                _vm.claim.arise_client !== _vm.origin.arise_client &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.arise_client ? _vm.origin.arise_client : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.arise_client !== _vm.origin.arise_client &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.arise_client ? _vm.claim.arise_client : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("금액")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.arise_price &&
                (_vm.claim.arise_eschamge !== _vm.origin.arise_eschamge ||
                  _vm.claim.arise_price !== _vm.origin.arise_price) &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"requestClaimPrice originTextRight"},[(
                  _vm.claim.isOrigin &&
                  _vm.origin.arise_eschamge &&
                  _vm.claim.arise_eschamge !== _vm.origin.arise_eschamge &&
                  _vm.claim.requestStatus === '50'
                )?_c('div',{staticClass:"requestClaimPrice"},[_vm._v(" "+_vm._s(_vm.origin.arise_eschamge ? ("" + (_vm.origin.arise_currency_sign) + (_vm.origin.arise_eschamge)) : "")+" ")]):_vm._e(),(
                  _vm.claim.isOrigin &&
                  _vm.origin.arise_price &&
                  _vm.claim.arise_price !== _vm.origin.arise_price &&
                  _vm.claim.requestStatus === '50'
                )?_c('div',{staticClass:"requestClaimPrice"},[_vm._v(" "+_vm._s(_vm.origin.arise_price ? ("" + (_vm.origin.arise_currency_sign) + (_vm.origin.arise_price)) : "")+" ")]):_vm._e()]):_vm._e()]:_vm._e(),_c('div',{staticClass:"requestClaimPrice"},[(_vm.claim.arise_eschamge)?_c('div',{staticClass:"requestClaimPrice",class:_vm.origin &&
                _vm.claim.arise_eschamge !== _vm.origin.arise_eschamge &&
                _vm.claim.requestStatus === '50'
                  ? 'red--text originText'
                  : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.claim.arise_eschamge ? ("₩" + (_vm.claim.arise_eschamge)) : ""))+" ")]):_vm._e(),_c('div',{staticClass:"requestClaimPrice",class:_vm.origin &&
                _vm.claim.arise_price !== _vm.origin.arise_price &&
                _vm.claim.requestStatus === '50'
                  ? 'red--text originText'
                  : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.claim.arise_price ? ("" + (_vm.claim.arise_currency_sign) + (_vm.claim.arise_price)) : ""))+" ")])])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("전가")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.arise_passtype &&
                _vm.claim.arise_passtype !== _vm.origin.arise_passtype &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.arise_passtype ? _vm.origin.arise_passtype : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.arise_passtype !== _vm.origin.arise_passtype &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.arise_passtype ? _vm.claim.arise_passtype : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("내용")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.arise_detail &&
                _vm.claim.arise_detail !== _vm.origin.arise_detail &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.arise_detail ? _vm.origin.arise_detail : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.arise_detail !== _vm.origin.arise_detail &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.arise_detail ? _vm.claim.arise_detail : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("VAT 포함여부")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.arise_vat &&
                _vm.claim.arise_vat !== _vm.origin.arise_vat &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.arise_vat === true ? "포함" : _vm.origin.arise_vat === false ? "미포함" : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.arise_vat !== _vm.origin.arise_vat &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.arise_vat === true ? "포함" : _vm.claim.arise_vat === false ? "미포함" : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBodyBottom"},[_vm._v("상태")]),_c('div',{staticClass:"claimReuqestBodyBottomRight"},[_c('div',[_vm._v(" "+_vm._s(_vm.origin ? _vm.origin.arise_status : "")+" ")])])])]),_c('div',[_c('div',{staticClass:"claimAreaTitle font-weight-bold",class:_vm.claim.type === 'pass' ? 'red--text ' : ''},[_vm._v(" 전가 ")]),_c('div',{staticClass:"claimRequestGrid"},[_c('div',{staticClass:"claimReuqestBody"},[_vm._v("월")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.pass_month &&
                _vm.origin.pass_id &&
                _vm.claim.pass_month !== _vm.origin.pass_month &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.pass_month ? _vm.origin.pass_month : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.pass_month !== _vm.origin.pass_month &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.pass_month ? _vm.claim.pass_month : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("구분")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.pass_type &&
                _vm.claim.pass_type !== _vm.origin.pass_type &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.pass_type ? _vm.origin.pass_type : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.pass_type !== _vm.origin.pass_type &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.pass_type ? _vm.claim.pass_type : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("업체명")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.pass_client &&
                _vm.claim.pass_client !== _vm.origin.pass_client &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.pass_client ? _vm.origin.pass_client : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.pass_client !== _vm.origin.pass_client &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.pass_client ? _vm.claim.pass_client : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("금액")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.pass_price &&
                (_vm.claim.pass_eschamge !== _vm.origin.pass_eschamge ||
                  _vm.claim.pass_price !== _vm.origin.pass_price) &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"requestClaimPrice originTextRight"},[(
                  _vm.claim.isOrigin &&
                  _vm.origin.pass_eschamge &&
                  _vm.claim.pass_eschamge !== _vm.origin.pass_eschamge &&
                  _vm.claim.requestStatus === '50'
                )?_c('div',{staticClass:"requestClaimPrice"},[_vm._v(" "+_vm._s(_vm.origin.pass_eschamge ? ("" + (_vm.origin.pass_currency_sign) + (_vm.origin.pass_eschamge)) : "")+" ")]):_vm._e(),(
                  _vm.claim.isOrigin &&
                  _vm.origin.pass_price &&
                  _vm.claim.pass_price !== _vm.origin.pass_price &&
                  _vm.claim.requestStatus === '50'
                )?_c('div',{staticClass:"requestClaimPrice"},[_vm._v(" "+_vm._s(_vm.origin.pass_price ? ("" + (_vm.origin.pass_currency_sign) + (_vm.origin.pass_price)) : "")+" ")]):_vm._e()]):_vm._e()]:_vm._e(),_c('div',{staticClass:"requestClaimPrice"},[(_vm.claim.pass_eschamge)?_c('div',{staticClass:"requestClaimPrice",class:_vm.origin &&
                _vm.claim.pass_eschamge !== _vm.origin.pass_eschamge &&
                _vm.claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.claim.pass_eschamge ? ("₩" + (_vm.claim.pass_eschamge)) : ""))+" ")]):_vm._e(),_c('div',{staticClass:"requestClaimPrice",class:_vm.origin &&
                _vm.claim.pass_price !== _vm.origin.pass_price &&
                _vm.claim.requestStatus === '50'
                  ? 'red--text originText'
                  : 'originText'},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.claim.pass_price ? ("" + (_vm.claim.pass_currency_sign) + (_vm.claim.pass_price)) : ""))+" ")])])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("내용")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.pass_detail &&
                _vm.claim.pass_detail !== _vm.origin.pass_detail &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.pass_detail ? _vm.origin.pass_detail : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.pass_detail !== _vm.origin.pass_detail &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.pass_detail ? _vm.claim.pass_detail : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBody"},[_vm._v("VAT 포함여부")]),_c('div',{staticClass:"claimReuqestBodyRight"},[(_vm.origin)?[(
                _vm.claim.isOrigin &&
                _vm.origin.pass_vat &&
                _vm.claim.pass_vat !== _vm.origin.pass_vat &&
                _vm.claim.requestStatus === '50'
              )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.pass_vat === true ? "포함" : _vm.origin.pass_vat === false ? "미포함" : "")+" ")]):_vm._e()]:_vm._e(),_c('div',{class:_vm.origin &&
              _vm.claim.pass_vat !== _vm.origin.pass_vat &&
              _vm.claim.requestStatus === '50'
                ? 'red--text originText'
                : 'originText'},[_vm._v(" "+_vm._s(_vm.claim.pass_vat === true ? "포함" : _vm.claim.pass_vat === false ? "미포함" : "")+" ")])],2),_c('div',{staticClass:"claimReuqestBodyBottom"},[_vm._v("상태")]),_c('div',{staticClass:"claimReuqestBodyBottomRight"},[_c('div',[_vm._v(" "+_vm._s(_vm.origin ? _vm.origin.pass_status : "")+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }