<template>
  <v-dialog
    :value="email_dialog"
    max-width="800px"
    scrollable
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
  >
    <v-card>
      <v-toolbar color="primary" dark dense>
        <v-card-title
          ><span>{{ $store.state.printType }} 이메일 전송 </span></v-card-title
        >
      </v-toolbar>

      <v-card-text style="height: 600px" class="pt-3">
        <div class="emailRowCell pt-3">
          <div class="mailTitle">받는 사람</div>
          <div class="mailBody">
            <v-text-field
              hide-details
              v-model="emailInput.reciver"
              outlined
              dense
              clearable
              @change="emailCheck($event)"
            ></v-text-field>
          </div>
        </div>
        <div class="emailRowCell pt-3">
          <div class="mailTitle">참조</div>
          <div class="mailBody">
            <v-text-field
              hide-details
              v-model="emailInput.cabonCopy"
              outlined
              dense
              clearable
              placeholder="abc@naver.com,abc2@naver.com 형태로 입력 해주세요."
            ></v-text-field>
          </div>
        </div>
        <div class="emailRowCell pt-3">
          <div class="mailTitle">제목</div>
          <div class="mailBody">
            <v-text-field
              hide-details
              v-model="emailInput.title"
              outlined
              dense
              clearable
            ></v-text-field>
          </div>
        </div>

        <div class="emailRowCell pt-3" style="height: 10em">
          <div class="mailTitle">첨부파일</div>
          <div
            class="mailBody"
            style="
              height: 110px;
              border: thin solid;
              overflow-y: overlay;
              overflow-x: hidden;
              margin-top: 3px;
              /* background-color: green; */
            "
          >
            <div style="display: flex; align-items: center" class="pl-1">
              <div style="width: 80%">
                {{ $store.state.emailFile.name }}
              </div>
              <div style="width: 20%">
                {{
                  formaBytes(
                    $store.state.emailFile ? $store.state.emailFile.size : 0,
                  )
                }}
              </div>
            </div>
            <div v-for="file in attachmentList" :key="file.name">
              <div style="display: flex; align-items: center" class="pl-1">
                <div style="width: 80%">
                  {{ file.name }}
                </div>
                <div style="width: 20%">
                  {{ formaBytes(file.size ? file.size : 0) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="emailRowCell pt-3" style="height: 28.8em">
          <div class="mailTitle">내용</div>
          <div class="mailBody">
            <VueEditor
              v-model="emailInput.content"
              :editorToolbar="customToolbar"
              style="height: 25em"
            ></VueEditor>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green" @click="emailSend()" text>
          <v-icon class="mr-2">mdi-email-arrow-right</v-icon>전송
        </v-btn>
        <v-btn color="primary" @click="$store.state.isDialog = false" text>
          닫 기
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { sendEmail } from "@/api/print";
import { mapGetters } from "vuex";

export default {
  components: {
    VueEditor,
  },
  computed: {
    ...mapGetters({
      emailInput: "getEmailInput",
      attachmentList: "getAttachmentList",
    }),
    email_dialog() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "email_dialog"
        ? true
        : false;
    },
  },
  data() {
    return {
      reciver: "",
      cabonCopy: "",
      title: "",
      content: "",

      customToolbar: [
        [{ font: [] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
    };
  },
  methods: {
    emailCheck(str) {
      const reg_email =
        // eslint-disable-next-line no-useless-escape
        /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      if (str && !reg_email.test(str)) {
        this.reciver = "";
        this.$store.commit("setSnackBar", `이메일 형식으로 입력해주세요.`);
        return false;
      } else {
        return true;
      }
    },
    validationEmailForm() {
      if (!this.emailInput.reciver) {
        this.$store.commit("setSnackBar", `받는 사람을 입력해주세요.`);
        return false;
      }

      if (!this.emailInput.title) {
        this.$store.commit("setSnackBar", `제목을 입력해주세요.`);
        return false;
      }
      return true;
    },
    blobToFile(theBlob, fileName) {
      return new File([theBlob.data], fileName, {
        lastModified: new Date().getTime(),
      });
    },
    async snedFormParse(file) {
      // let contentForm = "";
      // const item = this.$store.state.printForm.item.production_item;
      const fileName = `${this.$store.state.printForm.order}_${this.$store.state.printType}.pdf`;
      // contentForm = `* 본 메일은 발신 전용으로 수신이 불가합니다.<p/>발신처 : ${
      //   this.$store.state.printForm.company
      // } (TEL : ${this.addAutoHyphen(
      //   this.$store.state.printForm.tel,
      // )}}) <p/>발신자 : ${this.$store.state.auth_name} (${
      //   this.$store.state.auth_uuid
      // })<p/>문서 유형 : ${this.$store.state.printType}<p/>오더 번호 : ${
      //   this.$store.state.printForm.order
      // }<p/>ITEM NO : ${item} <p/>=======================================<p/>${
      //   this.emailInput.content
      // }`;
      file.set("reciver", this.emailInput.reciver);
      file.set("cabonCopy", this.emailInput.cabonCopy);
      file.set("title", this.emailInput.title);
      file.set("content", this.emailInput.content);
      file.set("filename", fileName);
      file.set("printType", this.$store.state.printType);
      // console.log(this.attachmentList);
    },

    async emailSend() {
      if (!this.validationEmailForm()) return;
      if (confirm("해당 주소로 이메일을 전송하시겠습니까?")) {
        const file = this.$store.state.emailForm;

        this.snedFormParse(file);

        try {
          const result = await sendEmail(file);
          this.$store.commit("setSnackBar", result.data);
          this.reciver = "";
          this.cabonCopy = "";
          this.title = "";
          this.content = "";
          this.$store.state.isDialog = false;
        } catch (e) {
          alert(e);
        }
      }
    },
  },
};
</script>
