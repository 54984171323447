import purchasecalculate from "../purchase/purchaseCalculateHeaders";
import purchasedeadline from "../purchase/purchaseDeadlineHeaders";
import salescalculate from "../sales/headersCalculateSales";
import salesdeadline from "../sales/headersDeadlineSales";

export default {
  purchasecalculate,
  purchasedeadline,
  salescalculate,
  salesdeadline,
};
