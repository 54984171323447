<template>
  <div class="pr-3 pl-3">
    <h2 class="">{{ title }}</h2>
    <div class="pt-6">
      <div class="purchaseRequestGrid">
        <div class="topLeft">구분</div>
        <div class="topRight">{{ form.kind }}</div>
        <div class="topLeft">년도</div>
        <div class="topRight">{{ form.year }}</div>
        <div class="topLeft">월</div>
        <div class="topRight">
          {{ form.carried ? `${form.month} (이월됨)` : `${form.month}` }}
        </div>
        <div class="topLeft">시즌</div>
        <div class="topRight">{{ form.season }}</div>
        <div class="topLeft">ORDER NO</div>
        <div class="topRight">{{ form.order }}</div>
        <div class="topLeft">발주처</div>
        <div class="topRight">{{ form.brand }}</div>
        <div class="topLeft">아이템</div>
        <div class="topRight">{{ form.item }}</div>
        <div class="topLeft">거래처</div>
        <div class="topRight">{{ form.client }}</div>
        <div class="calculateMemo">
          <span class="pl-1 bodyFontSize"> 비고 </span>
          <v-textarea
            v-model="form.memo"
            rows="7"
            dense
            hide-details
            class="lefted-input pl-1 pt-1"
            disabled
            no-resize
          >
          </v-textarea>
        </div>
      </div>
      <div class="pt-6">
        <div class="purchaseBottomGrid">
          <div
            v-for="header in form.type === 'purchase'
              ? purchaseColorHeader
              : salesColorHeader"
            :key="header.value"
          >
            {{ header.text }}
          </div>
        </div>
        <div class="purchaseBottomBodyGrid">
          <div
            v-for="header in form.type === 'purchase'
              ? purchaseColorHeader
              : salesColorHeader"
            :key="header.value"
            :class="{ numTypeText: header.type === 'number' }"
          >
            <template v-if="form.type === 'purchase'">
              <span v-if="header.value === 'color'">{{
                form.color ? `${form.color} (+${form.colorSize - 1})` : ""
              }}</span>
              <span v-if="header.value === 'quantity'">{{
                form.totalQuantity
                  ? `${form.totalQuantity}${form.purUnitSign}`
                  : ""
              }}</span>
              <span v-if="header.value === 'purchaseQuantity'">{{
                form.purchaseQuantity
                  ? `${form.purchaseQuantity}${form.purUnitSign}`
                  : ""
              }}</span>
              <span v-if="header.value === 'purchaseUnitPrice'">{{
                form.purchaseUnitPrice
                  ? `${form.purCurrencySign}${form.purchaseUnitPrice}`
                  : ""
              }}</span>
              <span v-if="header.value === 'amount'">{{
                form.amount ? `${form.purCurrencySign}${form.amount}` : ""
              }}</span>
              <span v-if="header.value === 'colorSurchargePrice'">{{
                form.colorSurchargePrice
                  ? `${form.purCurrencySign}${form.colorSurchargePrice}`
                  : ""
              }}</span>
              <span v-if="header.value === 'surchargePrice'">{{
                form.surchargePrice
                  ? `${form.purCurrencySign}${form.surchargePrice}`
                  : ""
              }}</span>
              <span v-if="header.value === 'totalAmount'">{{
                form.totalAmount
                  ? `${form.purCurrencySign}${form.totalAmount}`
                  : ""
              }}</span>
            </template>

            <template v-if="form.type === 'sales'">
              <span v-if="header.value === 'color'">{{
                form.color ? `${form.color} (+${form.colorSize - 1})` : ""
              }}</span>
              <span v-if="header.value === 'totalQuantity'">{{
                form.totalQuantity
                  ? `${form.totalQuantity}${form.unitSign}`
                  : ""
              }}</span>
              <span v-if="header.value === 'salesQuantity'">{{
                form.salesQuantity
                  ? `${form.salesQuantity}${form.unitSign}`
                  : ""
              }}</span>
              <span v-if="header.value === 'salesUnitPrice'">{{
                form.salesUnitPrice
                  ? `${form.salesCurrencySign}${form.salesUnitPrice}`
                  : ""
              }}</span>
              <span v-if="header.value === 'amount'">{{
                form.salesTotalAmount
                  ? `${form.salesCurrencySign}${form.salesTotalAmount}`
                  : ""
              }}</span>
              <span v-if="header.value === 'colorSurchargeUnit'">{{
                form.colorSurchargeUnit
                  ? `${form.salesCurrencySign}${form.colorSurchargeUnit}`
                  : ""
              }}</span>
              <span v-if="header.value === 'salesSurchargeUnit'">{{
                form.salesSurchargeUnit
                  ? `${form.salesCurrencySign}${form.salesSurchargeUnit}`
                  : ""
              }}</span>
              <span v-if="header.value === 'salesTotalAmount'">{{
                form.salesTotalAmount
                  ? `${form.salesCurrencySign}${form.salesTotalAmount}`
                  : ""
              }}</span>
            </template>
          </div>
        </div>
        <div
          v-for="(color, i) in form.colorList"
          :key="i"
          class="purchaseBottomBodyGrid"
        >
          <div
            v-for="header in form.type === 'purchase'
              ? purchaseColorHeader
              : salesColorHeader"
            :key="header.value"
            :class="{ numTypeText: header.type === 'number' }"
          >
            <template v-if="form.type === 'purchase'">
              <template v-if="header.signType === 'unit'">
                <span v-if="header.value === 'quantity'">
                  {{
                    form.releaseColor
                      ? `${form.releaseColor[i].quantity}${form.purUnitSign}`
                      : ""
                  }}
                </span>
                <span v-if="header.value === 'purchaseQuantity'">
                  {{
                    color[header.value]
                      ? `${color[header.value]}${form.purUnitSign}`
                      : ""
                  }}
                </span>
              </template>
              <template v-else-if="header.signType === 'currency'">
                <span v-if="header.value === 'totalAmount'">
                  {{
                    color[header.value]
                      ? `${form.purCurrencySign}${colorTotalAmount(i)}`
                      : ""
                  }}
                </span>
                <span v-else>
                  {{
                    color[header.value]
                      ? `${form.purCurrencySign}${color[header.value]}`
                      : ""
                  }}
                </span>
              </template>
              <template v-else>
                {{ color[header.value] ? color[header.value] : "" }}
              </template>
            </template>

            <template v-if="form.type === 'sales'">
              <template v-if="header.signType === 'unit'">
                <span v-if="header.value === 'totalQuantity'">
                  {{
                    form.releaseColor
                      ? `${form.releaseColor[i].quantity}${form.unitSign}`
                      : ""
                  }}
                </span>
                <span v-if="header.value === 'salesQuantity'">
                  {{
                    form.colorList
                      ? `${form.colorList[i].salesQuantity}${form.unitSign}`
                      : ""
                  }}
                </span>
              </template>
              <template v-else-if="header.signType === 'currency'">
                <span v-if="header.value === 'salesTotalAmount'">
                  {{
                    form.salesTotalAmount
                      ? `${form.salesCurrencySign}${colorTotalAmount(i)}`
                      : ""
                  }}
                </span>
                <span v-else>
                  {{
                    color[header.value]
                      ? `${form.salesCurrencySign}${color[header.value]}`
                      : ""
                  }}
                </span>
              </template>
              <template v-else>
                {{ color[header.value] ? color[header.value] : "" }}
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      styleHeader: [
        {
          text: "STYLE NO",
          value: "style",
          align: "center",
          width: "40%",
        },
        {
          value: "count",
          align: "center",
          width: "50%",
        },
        { value: "actions", width: "10%", sortable: false },
      ],
      purchaseColorHeader: [
        {
          text: "컬러",
          value: "color",
          align: "center",
          width: "40%",
        },
        {
          text: "출고수량",
          value: "quantity",
          align: "center",
          width: "30%",
          type: "number",
          signType: "unit",
        },
        {
          text: "수량",
          value: "purchaseQuantity",
          align: "center",
          width: "30%",
          type: "number",
          signType: "unit",
        },
        {
          text: "단가",
          value: "purchaseUnitPrice",
          align: "center",
          width: "40%",
          type: "number",
          signType: "currency",
        },
        {
          text: "금액",
          value: "amount",
          align: "center",
          width: "30%",
          type: "number",
          signType: "currency",
        },
        {
          text: "염색 Surcharge",
          value: "colorSurchargePrice",
          align: "center",
          width: "30%",
          type: "number",
          signType: "currency",
        },
        {
          text: "제직 Surcharge",
          value: "surchargePrice",
          align: "center",
          width: "40%",
          type: "number",
          signType: "currency",
        },
        {
          text: "금액 합",
          value: "totalAmount",
          align: "center",
          width: "30%",
          type: "number",
          signType: "currency",
        },
      ],
      salesColorHeader: [
        {
          text: "컬러",
          value: "color",
          align: "center",
          width: "40%",
        },
        {
          text: "출고수량",
          value: "totalQuantity",
          align: "center",
          width: "30%",
          type: "number",
          signType: "unit",
        },
        {
          text: "수량",
          value: "salesQuantity",
          align: "center",
          width: "30%",
          type: "number",
          signType: "unit",
        },
        {
          text: "단가",
          value: "salesUnitPrice",
          align: "center",
          width: "40%",
          type: "number",
          signType: "currency",
        },
        {
          text: "금액",
          value: "amount",
          align: "center",
          width: "30%",
          type: "number",
          signType: "currency",
        },
        {
          text: "컬러 Surcharge",
          value: "colorSurchargeUnit",
          align: "center",
          width: "30%",
          type: "number",
          signType: "currency",
        },
        {
          text: "건 당 Surcharge",
          value: "salesSurchargeUnit",
          align: "center",
          width: "40%",
          type: "number",
          signType: "currency",
        },
        {
          text: "금액 합",
          value: "salesTotalAmount",
          align: "center",
          width: "30%",
          type: "number",
          signType: "currency",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      form: "getRequestForm",
    }),
    heightTable() {
      return `${this.windowHeight - 800}px`;
    },
    totalQuantity() {
      return this.order.color_list.length
        ? this.order.color_list.reduce((a, c) => a + c.quantity, 0)
        : 0;
    },
    title() {
      const type = this.form.type === "purchase" ? "매입" : "매출";
      return `${type}(${
        this.form.name === "purchasecalculate" ||
        this.form.name === "salescalculate"
          ? "마감 승인"
          : this.form.name === "salesdeadline" ||
            this.form.name === "purchasedeadline"
          ? "마감 취소"
          : "취소"
      }) `;
    },
  },
  methods: {
    colorTotalAmount(index) {
      const color = this.form.colorList[index];
      const type = this.form.type;

      let totalAmount = color.amount ? +color.amount : 0;
      if (color.colorSurchargePrice)
        totalAmount +=
          type === "purchase"
            ? +color.colorSurchargePrice
            : +color.colorSurchargeUnit;
      if (type === "purchase" && color.surchargePrice)
        totalAmount += +color.surchargePrice;
      return +totalAmount.toFixed(2);
    },
  },
};
</script>

<style></style>
