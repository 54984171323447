import { mapGetters } from "vuex";
import manufactureForm from "@/components/pages/requestDialog/ManufactureForm.vue";
import releaseForm from "@/components/pages/requestDialog/ReleaseForm.vue";
import orderForm from "@/components/pages/requestDialog/OrderForm";
import claimForm from "@/components/pages/requestDialog/ClaimForm";
import purchaseList from "@/components/pages/requestDialog/CaculDeadList/PurchaseList.vue";
import purchaseDeadList from "@/components/pages/requestDialog/CaculDeadList/PurchaseDeadList.vue";
import salesList from "@/components/pages/requestDialog/CaculDeadList/SalesList.vue";
import salesDeadList from "@/components/pages/requestDialog/CaculDeadList/SalesDeadList.vue";
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import { getSelectOrder } from "@/api/order";
import { notParseClaimFInd } from "@/api/claim.js";
import { tempConfirm, tempCompanion } from "@/api/approval.js";
import dayjs from "dayjs";
export default {
  components: {
    manufactureForm,
    releaseForm,
    orderForm,
    claimForm,
    purchaseList,
    purchaseDeadList,
    salesList,
    salesDeadList,
    LoadingSpinner,
  },
  data() {
    return {
      approvalHeader: [
        {
          text: "접수일자",
          value: "status",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "오더",
          value: "order",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          text: "발주처",
          value: "liaison.name",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "아이템",
          value: "liaison.name",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "생산처",
          value: "production",
          align: "center",
          class: "tableHeaderBody",
        },

        {
          text: `생산처 아이템`,
          value: "liaison.name",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "담당자",
          value: "liaison.name",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "상태",
          value: "status",
          align: "center",
          class: "tableHeaderBody",
        },
        {
          text: "메모",
          value: "memo",
          align: "center",
          class: "tableHeaderRigth",
        },
      ],
      setOrder: {},
      beforeOrder: {},
      afterOrder: {},
      setClaim: {},
      saveClaim: {},
    };
  },
  computed: {
    ...mapGetters({
      requestForm: "getRequestForm",
      requestSetList: "getRequestList",
      itemList: "getItemList",
      clientList: "getClientList",
      userList: "getUserList",
    }),
    dialog() {
      return this.$store.state.requestDialog;
    },
    docKind() {
      return this.requestSetList.name === "order"
        ? "오더"
        : this.requestSetList.name === "release"
        ? "출고"
        : this.requestSetList.name === "manufacture"
        ? "가공"
        : "클레임";
    },
  },
  methods: {
    //1.set Form =================================
    async setForm(data) {
      if (!data.id) return;

      await this.$store.dispatch("SELECT_ORDER", {
        company: data.company,
        order: data.order,
      });
      await this.$store.dispatch("getaApprovalComId", {
        approvalId: data.approvalId,
        route: data.route,
      });

      this.setOrder = JSON.parse(JSON.stringify(this.$store.state.selectOrder));
      this.$store.dispatch("SET_REQUEST_FORM_DATA", {
        approvalId: data.approvalId,
        name: data.name,
        route: data.route,
        id: data.id,
        order: data.order,
      });
    },

    manufactureFormData(data) {
      const requestData = this.manuFormParsing(
        this.setOrder.manufacture.find((x) => x.id === data.id),
      );
      requestData.isOrigin = false;

      return data.status === "50"
        ? this.modifyManufacture(this.copyData(data.data), requestData)
        : requestData;
    },

    manuFormParsing(requestData) {
      requestData.formKind = "manufacture";
      requestData.order = this.setOrder.order;
      requestData.place_manufacture = this.clientList.find(
        (x) => x._id === requestData.place_manufacture,
      );
      requestData.liaison = this.userList.find(
        (x) => x._id === requestData.liaison,
      );

      requestData.item = this.itemList.find((x) => x._id === requestData.item);
      if (requestData.surcharge_unit)
        requestData.surcharge_unit = requestData.surcharge_unit.price;

      requestData.total_quantity_sum = requestData.color_list.reduce(
        (r, c) => r + +c.quantity + (c.quantity_sample ? c.quantity_sample : 0),
        0,
      );
      requestData.date_payment = this.dateFormat(requestData.date_payment);

      if (requestData.inspection)
        requestData.inspection = this.clientList.find(
          (x) => x._id === requestData.inspection,
        );

      if (!requestData.currency_sign) requestData.currency_sign = "₩";

      if (requestData.color_list.length < 15) {
        const num = 15 - requestData.color_list.length;
        for (let i = 0; i < num; i++) {
          requestData.color_list.push({});
        }
      }
      return requestData;
    },

    modifyManufacture(moidfy, origin) {
      const requestData = this.manuFormParsing(moidfy);
      const keys = Object.keys(moidfy);
      requestData.origin = {};

      keys.forEach((x) => {
        if (
          x !== "color_list" &&
          x !== "style_list" &&
          x !== "eschamge" &&
          x !== "unit_price" &&
          requestData[x] !== origin[x]
        ) {
          requestData.origin[x] = origin[x];
        }
      });
      requestData.origin.color_list = origin.color_list;
      requestData.origin.style_list = origin.style_list;
      requestData.origin.unit_price = origin.unit_price;
      requestData.origin.eschamge = origin.eschamge;

      if (!requestData.origin.unit_sign)
        requestData.origin.unit_sign = origin.unit_sign;

      if (!requestData.origin.currency_sign)
        requestData.origin.currency_sign = origin.currency_sign;

      requestData.isOrigin = true;

      return requestData;
    },

    releaseFormData(data) {
      const requestData = this.relFormParsign(
        this.setOrder.release.find((x) => x.id === data.id),
      );
      requestData.isOrigin = false;

      return data.status === "50"
        ? this.modifyRelease(this.copyData(data.data), requestData)
        : requestData;
    },

    relFormParsign(requestData) {
      requestData.formKind = "release";

      requestData.place_forward = this.clientList.find(
        (x) => x._id === requestData.place_forward,
      );
      requestData.place_receive = this.clientList.find(
        (x) => x._id === requestData.place_receive,
      );
      requestData.place_sales = this.clientList.find(
        (x) => x._id === requestData.place_sales,
      );
      requestData.item = this.itemList.find((x) => x._id === requestData.item);
      requestData.liaison = this.userList.find(
        (x) => x._id === requestData.liaison,
      );

      requestData.requestColor = requestData.color_list
        ? requestData.color_list
        : [];
      requestData.order = this.setOrder.order;

      requestData.isPur =
        !!requestData.purchase && requestData.purchase.quantity;

      if (requestData.kind === 9 || requestData.kind === 15) {
        requestData.surcharge_unit = requestData.sales.surcharge_unit
          ? this.usingComma(requestData.sales.surcharge_unit)
          : "";
        requestData.color_surcharge_unit = requestData.sales
          .color_surcharge_unit
          ? this.usingComma(requestData.sales.color_surcharge_unit)
          : "";
      }

      if (requestData.requestColor.length < 13) {
        const num = 13 - requestData.requestColor.length;
        for (let i = 0; i < num; i++) {
          requestData.requestColor.push({});
        }
      }

      return requestData;
    },

    modifyRelease(moidfy, origin) {
      const requestData = this.relFormParsign(moidfy);
      const keys = Object.keys(moidfy);
      requestData.origin = {};

      keys.forEach((x) => {
        if (
          x !== "color_list" &&
          x !== "purchase" &&
          x !== "sales" &&
          requestData[x] !== origin[x]
        )
          requestData.origin[x] = origin[x];
      });

      if (!requestData.origin.unit_sign)
        requestData.origin.unit_sign = origin.unit_sign;
      if (!requestData.origin.currency_sign)
        requestData.origin.currency_sign = origin.currency_sign;

      requestData.origin.color_list = origin.color_list;
      requestData.origin.purchase = origin.purchase;
      requestData.origin.sales = origin.sales;

      requestData.isOrigin = true;

      // if (!moidfy.manufacture && !moidfy.release && !moidfy.appearance)
      if (moidfy.stock) requestData.isStock = true;

      return requestData;
    },

    orderFormData(data) {
      const requestData = this.orderFormParsing(
        JSON.parse(JSON.stringify(this.setOrder)),
        data,
      );
      requestData.isOrigin = false;

      return data.status === "50"
        ? this.modifyOrderParsing(this.copyData(data.data), requestData)
        : requestData;
    },

    orderFormParsing(requestData, userData) {
      const item = this.itemList.find((x) => x._id === requestData.item);

      requestData.id = requestData.order;
      requestData.formKind = "order";
      requestData.item = item ? item.name : "";
      requestData.productionItem = item ? item.production_item : "";
      requestData.production = item ? item.production.name : "";
      requestData.client = this.clientList.find(
        (x) => x._id === requestData.client,
      );

      if (requestData.ctype)
        requestData.ctype = this.$store.state.ctypeList.find(
          (x) => x._id === requestData.ctype,
        ).name;

      requestData.gender = !requestData.gender
        ? "선택안함"
        : requestData.gender === "m"
        ? "남성"
        : "여성";

      requestData.order_kind = requestData.order_kind
        ? requestData.order_kind === "finished"
          ? "완제품"
          : requestData.order_kind === "post"
          ? "후가공"
          : "생지"
        : "";

      requestData.purchase_classification =
        !requestData.purchase_classification ||
        requestData.purchase_classification === "domestic"
          ? "내수"
          : "수입";

      requestData.liaison = this.userList.find(
        (x) =>
          x._id ===
          (userData?.data?.liaison
            ? userData.data.liaison.name
            : requestData.liaison && requestData.liaison.name
            ? requestData.liaison.name
            : requestData.liaison
            ? requestData.liaison
            : userData.liaisonName),
      );
      requestData.liaison = requestData.liaison ? requestData.liaison.name : "";

      if (requestData.color_list && requestData.color_list.length) {
        requestData.colorTotal = requestData.color_list.reduce(
          (a, r) => a + (+r.quantity ? +r.quantity : 0),
          0,
        );
      }

      if (requestData.style_list.length < 8) {
        const num = 8 - requestData.style_list.length;
        for (let i = 0; i < num; i++) {
          requestData.style_list.push("");
        }
      }

      if (requestData.color_list.length < 8) {
        const num = 8 - requestData.color_list.length;
        for (let i = 0; i < num; i++) {
          requestData.color_list.push("");
        }
      }

      return requestData;
    },

    modifyOrderParsing(modify, origin) {
      const requestData = this.orderFormParsing(modify);
      requestData.origin = origin;
      requestData.isOrigin = true;
      return requestData;
    },

    claimFormData(data) {
      const requestData = JSON.parse(JSON.stringify(this.setClaim));
      requestData.modifyData = data.data;

      requestData.status = data.status;
      // const claimSaveData = this.$store.state.selectClaimList.find(
      //   (x) => x.id === data.data.id,
      // );

      // if (claimSaveData)
      //   this.saveClaim = JSON.parse(JSON.stringify(claimSaveData));

      this.claimFormDataParsing(requestData, data);

      return requestData;
    },

    async claimFormDataParsing(data, approval) {
      data.formType =
        approval["id"].split("_")[1] === "arise" ? "arise" : "pass";

      data.formKind = "claim";

      data.month =
        data.formType === "arise" ? data.arise_month : data.pass_month;

      data.liaison =
        data.liaison === "미선택"
          ? data.liaison
          : data.liaison.name
          ? data.liaison.name
          : "미선택";

      data.price =
        data.formType === "arise" ? data.arise_price : data.pass_price;
      data.eschamge =
        data.formType === "arise" ? data.arise_eschamge : data.pass_eschamge;
      data.client =
        data.formType === "arise" ? data.arise_client : data.pass_client;
      data.status =
        data.formType === "arise" ? data.arise_status : data.pass_status;
      data.arise_passtype = data.arise_passtype
        ? data.arise_passtype === 1
          ? "협의중"
          : data.arise_passtype === 2
          ? "불가"
          : data.arise_passtype === 3
          ? "전가"
          : ""
        : "";
      data.detail =
        data.formType === "arise" ? data.arise_detail : data.pass_detail;
      const type = data.formType === "arise" ? data.arise_type : data.pass_type;

      data.type =
        type === 2 || type === "매입"
          ? "매입"
          : type === 3 || type === "매출"
          ? "매출"
          : type === 4 || type === "비용"
          ? "비용"
          : "발행 예정";
      data.currency_sign =
        data.formType === "arise"
          ? data.arise_currency_sign
          : data.pass_currency_sign;

      if (data.modifyData) {
        const approvalData = data.modifyData;
        const modifyData = {};
        modifyData.order = approvalData.order ? approvalData.order : "";

        modifyData.month =
          data.formType === "arise"
            ? approvalData.ariseMonth
            : approvalData.passMonth;
        modifyData.liaison = approvalData.liaison
          ? approvalData.liaison
          : "미선택";
        modifyData.type =
          data.formType === "arise"
            ? approvalData.arise_type
            : approvalData.pass_type;

        modifyData.currency_sign =
          data.formType === "arise"
            ? approvalData.arise_currency_sign
            : approvalData.pass_currency_sign;
        modifyData.detail =
          data.formType === "arise"
            ? approvalData.arise_detail
            : approvalData.pass_detail;
        modifyData.client =
          data.formType === "arise"
            ? approvalData.arise_client
            : approvalData.pass_client;

        modifyData.price =
          data.formType === "arise"
            ? approvalData.arise_price
            : approvalData.pass_price;
        modifyData.arise_passtype = approvalData.arise_passtype
          ? approvalData.arise_passtype === "(전가 협의중)"
            ? "협의중"
            : approvalData.arise_passtype === "(전가 불가)"
            ? "불가"
            : approvalData.arise_passtype === "(전가)"
            ? "전가"
            : ""
          : "";
        modifyData.eschamge =
          data.formType === "arise"
            ? approvalData.arise_eschamge
            : approvalData.pass_eschamge;
        modifyData.currency_sign =
          data.formType === "arise"
            ? approvalData.arise_currency_sign
            : approvalData.pass_currency_sign;
        data.modifyData = { ...modifyData };
      }
    },
    //set Form 끝 =================================
    //2. 승인  =================================
    async confirm(status) {
      this.save(status);
      // if (this.$store.state.relApprovalList.length)
      //   await this.$store.dispatch("CANCEL_CALCULATE_APPROVAL");
    },

    async claimConfirm() {
      const approval = this.$store.state.approvalForm;
      const saveOrder = this.$store.state.selectOrder;
      const claim = this.$store.state.requestForm.modifyData;
      const beforeClaim = this.$store.state.selectClaim;

      claim._id = this.$store.state.requestForm._id;
      claim.company = this.$store.state.auth_company;
      const status = this.$store.state.requestForm.status;

      //승인요청
      if (status === "20") {
        if (claim.liaison === "미선택") delete claim.liaison;
        if (claim.order === "미선택") delete claim.order;
        if (this.$store.state.requestForm.formType === "arise") {
          claim.arise_date_modify = new Date(Date.now()); // 수정 날짜
          claim.arise_status = "40";
          claim.pass_status = this.setClaim.pass_status;
          claim.pass_detail = this.setClaim.pass_detail;
        } else {
          claim.pass_date_modify = new Date(Date.now()); // 수정 날짜
          claim.pass_status = "40";
          claim.arise_status = this.setClaim.arise_status;
          claim.arise_detail = this.setClaim.arise_detail;
        }
        if (this.beforeOrder._id) {
          await this.$store.dispatch("CLAIM_RELEASE_PARSE", {
            target: this.$store.state.requestForm.formType,
            release: this.beforeOrder.release,
            form: claim,
          });
          if (Object.keys(this.$store.state.claimReleaseForm).length) {
            this.beforeOrder.release.push(this.$store.state.claimReleaseForm);
          }
        }
      }

      //수정요청
      if (status === "50") {
        const saveType = {
          newOrder: false,
          orderChange: false,
          modifyRelease: false,
          type: false,
          subType: false,
          addType: false,
          releaseEnd: false,
        };
        await this.claimSaveTypeCheck(beforeClaim, saveOrder, claim, saveType);
        if (claim.liaison === "미선택") delete claim.liaison;
        if (claim.order === "미선택") delete claim.order;

        if (this.$store.state.requestForm.formType === "arise") {
          claim.arise_date_modify = new Date(Date.now()); // 수정 날짜
          claim.arise_status = "40";
          claim.pass_kind = this.setClaim.pass_kind;
          claim.pass_price = this.setClaim.pass_price;
          claim.pass_client = this.setClaim.pass_client;
          claim.pass_currency_sign = this.setClaim.pass_currency_sign;
          claim.pass_month = this.setClaim.pass_month;
          claim.pass_type = this.setClaim.pass_type;
          claim.pass_eschamge = this.setClaim.pass_eschamge;
          claim.pass_year = this.setClaim.pass_year;
          claim.pass_detail = this.setClaim.pass_detail;
          if (this.setClaim.pass_status)
            claim.pass_status = this.setClaim.pass_status;
          // claim.pass_status = calim.pass_status;
          if (claim.arise_currency_sign === "₩") claim.arise_eschamge = "";
          claim.arise_currency_sign !== "₩"
            ? (claim.arise_eschamge = +claim.arise_eschamge)
            : "";
        } else {
          claim.pass_date_modify = new Date(Date.now()); // 수정 날짜
          claim.pass_status = "40";
          claim.arise_kind = this.setClaim.arise_kind;
          claim.arise_price = this.setClaim.arise_price;
          claim.arise_client = this.setClaim.arise_client;
          claim.arise_currency_sign = this.setClaim.arise_currency_sign;
          claim.arise_month = this.setClaim.arise_month;
          claim.arise_type = this.setClaim.arise_type;
          claim.arise_eschamge = this.setClaim.arise_eschamge;
          claim.arise_year = this.setClaim.arise_year;
          claim.arise_detail = this.setClaim.arise_detail;
          if (this.setClaim.arise_status)
            claim.arise_status = this.setClaim.arise_status;

          if (claim.pass_currency_sign === "₩") claim.pass_eschamge = "";
          claim.pass_currency_sign !== "₩"
            ? (claim.pass_eschamge = +claim.pass_eschamge)
            : "";
        }

        if (this.beforeOrder.order || this.afterOrder.order)
          await this.$store.dispatch("MODIFY_CLAIM_RELEASE_PARSE", {
            target: this.$store.state.requestForm.formType,
            saveType: saveType,
            beforeRelease: this.beforeOrder && this.beforeOrder.release,
            afterRelease: this.afterOrder && this.afterOrder.release,
            claim: claim,
          });
      }
      //삭제 요청일떄
      if (status === "10") {
        const release = this.beforeOrder.release;
        if (release) {
          const releaseIndex = this.beforeOrder.release.findIndex(
            (x) =>
              x.claim ===
              (this.$store.state.requestForm.formType === "arise"
                ? claim.arise_id
                : claim.pass_id),
          );
          if (releaseIndex !== -1)
            this.beforeOrder.release.splice(releaseIndex, 1);
        }
        if (claim.liaison === "미선택") delete claim.liaison;
        if (claim.order === "미선택") delete claim.order;
        if (this.$store.state.requestForm.formType === "arise") {
          claim.arise_date_modify = new Date(Date.now()); // 수정 날짜
          claim.arise_id = `${claim.arise_id}_delete_${this.numFromat(
            this.$store.state.selectClaimList.filter(
              (x) => x.arise_status === "99",
            ).length + 1,
          )}`;
          claim.arise_status = "99";

          if (this.setClaim.pass_status)
            claim.pass_status = this.setClaim.pass_status;
        } else {
          claim.pass_date_modify = new Date(Date.now()); // 수정 날짜
          claim.pass_id = `${claim.pass_id}_delete_${this.numFromat(
            this.$store.state.selectClaimList.filter(
              (x) => x.pass_status === "99",
            ).length + 1,
          )}`;
          claim.pass_status = "99";
          if (this.setClaim.arise_status)
            claim.arise_status = this.setClaim.arise_status;
        }
      }
      if (status === "60") {
        if (claim.liaison === "미선택") delete claim.liaison;
        if (claim.order === "미선택") delete claim.order;
        claim.isEndCase = true;
        if (this.$store.state.requestForm.formType === "arise") {
          claim.arise_status = "00";
          if (this.setClaim.pass_status)
            claim.pass_status = this.setClaim.pass_status;
          claim.arise_isEnd = true;
        } else {
          claim.pass_status = "00";
          if (this.setClaim.arise_status)
            claim.arise_status = this.setClaim.arise_status;
          claim.pass_isEnd = true;
        }
      }
      if (status === "90") {
        if (claim.liaison === "미선택") delete claim.liaison;
        if (claim.order === "미선택") delete claim.order;
        if (this.$store.state.requestForm.formType === "arise") {
          claim.isEndCase = false;
          claim.arise_status = "40";
          if (this.setClaim.pass_status)
            claim.pass_status = this.setClaim.pass_status;
          claim.arise_isEnd = false;
        } else {
          claim.isEndCase = false;
          claim.pass_status = "40";
          if (this.setClaim.arise_status)
            claim.arise_status = this.setClaim.arise_status;
          claim.pass_isEnd = false;
        }
      }

      approval.status = status === "10" ? "99" : "40";

      return { approval, saveOrder, claim };
    },

    orderConfirm() {
      const approval = this.$store.state.approvalForm;
      const saveOrder = JSON.parse(
        JSON.stringify(
          approval.status === "50" ? approval.data : this.setOrder,
        ),
      );
      if (this.requestForm.status === "50") {
        approval.data = {};
        saveOrder.__v = this.setOrder.__v;
      }
      if (this.requestForm.status === "90") {
        const DelOrders = this.$store.state.orderList.filter(
          (x) => x.status === "99",
        );
        saveOrder.order = `${this.setOrder.order}_Del${DelOrders.length++}`;
        approval.id = saveOrder.order;
      }
      approval.status = this.setOrder.status === "90" ? "99" : "40";
      saveOrder.status = this.setOrder.status === "90" ? "99" : "40";

      return { approval, saveOrder };
    },

    async productionConfirm() {
      const approval = this.$store.state.approvalForm;
      const saveOrder = this.$store.state.selectOrder;

      const index =
        this.requestForm.formKind === "release"
          ? saveOrder.release.findIndex((x) => this.requestForm.id === x.id)
          : saveOrder.manufacture.findIndex(
              (x) => this.requestForm.id === x.id,
            );

      const progress = saveOrder.progress.find(
        (x) => x.id === this.requestForm.id,
      );

      const status = this.requestForm.status === "90" ? "99" : "40";

      if (
        (this.requestForm.kind === 17 || this.requestForm.kind === 12) &&
        status === "40"
      )
        await this.stockMake();

      if (
        (this.requestForm.kind === 17 || this.requestForm.kind === 12) &&
        status === "99"
      )
        await this.deleteStockMake();

      if (this.requestForm.status === "50") {
        const beforData =
          this.requestForm.formKind === "release"
            ? saveOrder.release[index]
            : saveOrder.manufacture[index];

        approval.data.status = status;
        beforData.status = "99";

        if (this.requestForm.formKind === "release") {
          const release = approval.data;
          if (release.kind === 12)
            release.sales = {
              color_list: this.$store.state.saveStock.map((x) => {
                const color = {
                  name: x.name,
                  success_quantity: x.stock_quantity,
                  indexColorName: x.id,
                };
                return color;
              }),
            };

          beforData.id = `${beforData.id}_bak_${saveOrder.release.length}`;
          saveOrder.release.push(release);
        } else {
          beforData.id = `${beforData.id}_bak_${saveOrder.manufacture.length}`;
          saveOrder.manufacture.push(approval.data);
        }

        progress.history.push({
          id: beforData.id,
          log: progress.history.length + 1,
          date: new Date(Date.now()),
        });

        if (beforData.kind === 7) beforData.isOrder = false;

        if (status === "40")
          progress.log = this.progressLogMake(
            this.requestForm,
            this.requestForm.kind,
          );

        if (
          this.requestForm.formKind === "release" &&
          beforData.manufacture &&
          beforData.purchase &&
          0 < beforData.purchase.color_list.length
        )
          this.surchargeCheck(
            beforData.purchase,
            approval.data.purchase,
            approval.data.manufacture,
            saveOrder.manufacture,
          );

        approval.data = {};
      } else {
        this.requestForm.formKind === "release"
          ? (saveOrder.release[index].status = status)
          : (saveOrder.manufacture[index].status = status);

        if (this.requestForm.kind === 7 && this.requestForm.isOrder)
          this.isOrderChage(saveOrder, saveOrder.manufacture[index]);
      }

      approval.status = status;
      progress.status = status;

      if (
        this.requestForm.status === "90" &&
        this.requestForm.formKind === "release" &&
        this.requestForm.release
      )
        await this.cancelPreReleaseCacul(saveOrder);

      if (
        this.requestForm.status === "90" &&
        this.requestForm.formKind === "release" &&
        this.requestForm.appearance
      )
        this.cancelAppearanceCacul(saveOrder);

      if (
        this.requestForm.status === "90" &&
        this.requestForm.formKind === "release" &&
        this.requestForm.manufacture
      )
        this.releaseSurchargeCheck(saveOrder);

      if (
        this.requestForm.status === "90" &&
        this.requestForm.formKind === "release" &&
        !this.requestForm.manufacture &&
        !this.requestForm.appearance &&
        !this.requestForm.release
      )
        await this.$store.dispatch("APPROVAL_STOCK_DATA_SET", {
          minuse: [],
          pluse: this.requestForm.sales.color_list,
          unit_sign: this.requestForm.unit_sign,
        });

      if (this.requestForm.formKind === "release" && status === "99")
        await this.$store.dispatch(
          "FIND_CALCULATE_APPROVAL",
          this.requestForm.id,
        );

      return { approval, saveOrder };
    },

    setStock(release) {
      release.status = "40";
      if (this.requestForm.status === "20")
        this.$store.dispatch("SET_NEW_STOCK_DATA", release);
      else this.$store.dispatch("SET_MODIFY_STOCK_DATA", release);
    },

    isOrderChage(order, manufacture) {
      const progress = order.progress.find(
        (x) => x.kind === 7 && x.status !== "99" && x.id !== manufacture.id,
      );
      if (progress) {
        order.manufacture.find((x) => x.id === progress.id).isOrder = true;
      }

      manufacture.isOrder = false;
    },

    //승인 끝 =================================

    //3. 반려 =================================
    async companion(status) {
      this.save(status);
    },

    async productionCompanion() {
      const saveOrder = this.$store.state.selectOrder;
      const index =
        this.requestForm.formKind === "release"
          ? saveOrder.release.findIndex((x) => this.requestForm.id === x.id)
          : saveOrder.manufacture.findIndex(
              (x) => this.requestForm.id === x.id,
            );
      const approval = this.$store.state.approvalForm;
      const progress = saveOrder.progress.find(
        (x) => x.id === this.requestForm.id,
      );
      const status = this.requestForm.status === "20" ? "30" : "40";

      if (
        status === "40" &&
        (this.requestForm.kind === 17 || this.requestForm.kind === 12)
      )
        await this.stockCompanion();

      this.requestForm.formKind === "release"
        ? (saveOrder.release[index].status = status)
        : (saveOrder.manufacture[index].status = status);
      approval.status = status;
      progress.status = status;

      if (
        this.requestForm.status === "50" &&
        this.requestForm.formKind === "release" &&
        this.requestForm.release
      )
        await this.preCacul(saveOrder, this.copyData(approval.data));

      if (
        this.requestForm.status === "50" &&
        this.requestForm.formKind === "release" &&
        this.requestForm.appearance
      )
        this.appearanceCacul(saveOrder, approval);

      if (this.requestForm.isStock)
        await this.$store.dispatch("APPROVAL_STOCK_DATA_SET", {
          minuse: saveOrder.release[index].sales.color_list,
          pluse: this.requestForm.sales.color_list,
          unit_sign: this.requestForm.unit_sign,
        });

      if (this.requestForm.status === "50") approval.data = {};

      return { approval, saveOrder };
    },

    claimCompanion() {
      const approval = this.$store.state.approvalForm;
      const saveOrder = this.$store.state.selectOrder;

      const claim = this.setClaim;

      const status = this.requestForm.status;
      const saveType = this.$store.state.requestForm.formType;

      const targetReleaseIndex =
        saveOrder.release &&
        saveOrder.release.findIndex(
          (x) =>
            x.claim === (saveType === "arise" ? claim.arise_id : claim.pass_id),
        );

      if (status === "50" || status === "10") {
        if (targetReleaseIndex !== undefined && targetReleaseIndex !== -1)
          saveOrder.release[targetReleaseIndex].status = "40";
        approval.status = "40";
        if (this.$store.state.requestForm.formType === "arise") {
          claim.arise_status = "40";
        } else {
          claim.pass_status = "40";
        }
      }
      if (status === "60") {
        if (targetReleaseIndex !== undefined && targetReleaseIndex !== -1)
          saveOrder.release[targetReleaseIndex].status = "40";
        approval.status = "00";
        this.$store.state.requestForm.formType === "arise"
          ? (claim.arise_status = "40")
          : (claim.pass_status = "40");
      }
      //취소요청
      if (status === "90") {
        if (targetReleaseIndex !== undefined && targetReleaseIndex !== -1)
          saveOrder.release[targetReleaseIndex].status = "40";
        approval.status = "00";
        if (this.$store.state.requestForm.formType === "arise") {
          claim.arise_status = "00";
        } else {
          claim.pass_status = "00";
        }
      }
      //승인요청
      if (status === "20") {
        approval.status = "30";
        if (this.$store.state.requestForm.formType === "arise") {
          claim.arise_status = "30";
        } else {
          claim.pass_status = "30";
        }
      }
      claim.saveType = "companion";
      this.beforeOrder = { ...saveOrder };
      return { approval, saveOrder, claim };
    },

    orderCompanion() {
      const approval = this.$store.state.approvalForm;
      const saveOrder = JSON.parse(JSON.stringify(this.setOrder));
      if (this.requestForm.status === "50") approval.data = {};
      saveOrder.status = this.requestForm.status === "20" ? "30" : "40";
      approval.status = this.requestForm.status === "20" ? "30" : "40";

      return { approval, saveOrder };
    },
    //반려 끝 =================================

    //4.저장 로직 시작 =================================
    // 출고의뢰서 수정 요청 반려시 참조 문서가 선매입, 외관검사서, 재고 수량 일경우 kind 를 반환 하는 함수
    referenceDocKind(release) {
      let kind = "manufacture";
      if (release.kind === 15 || release.kind === 9) {
        if (release.appearance) kind = "appearance";
        if (release.release) kind = "release";
        if (!release.manufacture && !release.release && !release.appearance)
          kind = "stock";
      } else kind = "manufacture";

      return kind;
    },

    // // 출고의뢰서 삭제시 선매입, 외관검사서, 재고 수량 일경우 kind 를 반환 하는 함수\
    // deleteReferenceDocKind(release) {
    //   let kind = "manufacture";
    //   if (release.kind === 15 || release.kind === 9) {
    //     if (release.appearance) kind = "appearance";
    //     if (release.release) kind = "release";
    //     if (!release.manufacture && !release.release && !release.appearance)
    //       kind = "stock";
    //   } else kind = "manufacture";

    //   return kind;
    // },
    //생산페이지 저장 에 필요한 param set
    makeProdctuionParam(approval, saveStatus) {
      const modify = this.requestForm.requestDoc;
      const origin = this.requestForm.originDoc;
      const param = {
        isModify: this.requestForm.isModify,
        docType: approval.name,
        confirm_liaison: this.$store.state.auth_id,
        id: approval.id,
        approvalId: approval.approvalId,
      };

      if (param.docType === "manufacture" && param.isModify) {
        param.originPlace = origin.place_manufacture;
        param.originDatepayment = origin.date_payment;
      }

      if (param.docType === "release" && param.isModify) {
        param.originForwdPlace = origin.place_forward;
        param.originInspection = origin.inspection;
        param.originSalesPlace = origin.place_sales;
        param.originpReceivePlace = origin.place_receive;
        if (saveStatus === "companion")
          param.docKind = this.referenceDocKind(modify);
      }

      if (
        param.docType === "release" &&
        (modify.kind === 12 || modify.kind === 17)
      ) {
        param.modifyItem = modify.item;
        param.originItem = param.isModify && origin.item;
      }

      if (
        param.docType === "release" &&
        saveStatus === "confirm" &&
        modify.status === "90"
      )
        param.docKind = this.referenceDocKind(modify);

      return param;
    },

    //저장 로직
    async save(saveStatus) {
      this.$store.commit("setAPILoading", true);
      const approval = this.$store.state.approvalForm;
      approval.confirm_liaison = this.$store.state.auth_id;

      if (approval.route === "production")
        approval.param = this.makeProdctuionParam(approval, saveStatus);

      if (saveStatus === "confirm") await tempConfirm(approval);
      if (saveStatus === "companion") await tempCompanion(approval);

      this.$store._vm.$socket.emit("approval", {
        company: this.$store.state.auth_company,
      });
      // }

      await this.$store.dispatch("SELECT_APPROVAL_LIST");
      this.setRequestDataList();

      this.$store.commit("setAPILoading", false);
    },

    async setRequestDataList() {
      await this.$store.dispatch("SET_REQUEST_DATA", this.requestSetList.name);

      if (
        this.requestSetList.name === "purchasecalculate" ||
        this.requestSetList.name === "purchasedeadline" ||
        this.requestSetList.name === "salescalculate" ||
        this.requestSetList.name === "salesdeadline"
      )
        this.close();
      else this.setForm(this.requestSetList.list[0]);
    },

    //4.저장 로직 끝 =================================

    //5. 외관, 선매입 수량 계산 =================================
    appearanceCacul(order, approval) {
      const appearance = order.appearance.find(
        (x) => x.id === this.requestForm.appearance,
      );
      const colorList = order.release.find((x) => x.id === approval.id).sales
        .color_list;
      const modifyColorListr = approval.data.sales.color_list;
      appearance.color_list.forEach((x, i) => {
        x.release_quantity =
          x.release_quantity -
          +modifyColorListr[i].success_quantity +
          colorList[i].success_quantity;
        x.remaing_quantity = x.sucess_quantity - x.release_quantity;
        x.release_fail_quantity =
          x.release_fail_quantity -
          +modifyColorListr[i].fail_quantity +
          colorList[i].fail_quantity;
        x.remaing_fail_quantity = x.fail_quantity - x.release_fail_quantity;
      });
    },

    async preCacul(order, approval) {
      const saveStock = [];
      const release = order.release.find(
        (x) => x.id === this.requestForm.release,
      );
      await this.$store.dispatch("FIND_STOCK_DATA", release.id);

      const originRelease = order.release.find((x) => x.id === approval.id);

      approval.sales.color_list.forEach((x) => {
        release.sales.color_list[x.pur_index].success_quantity +=
          release.unit_sign !== approval.unit_sign
            ? +this.unitChange(release.unit_sign, +x.success_quantity)
            : +x.success_quantity;
      });

      originRelease.sales.color_list.forEach((x) => {
        release.sales.color_list[x.pur_index].success_quantity -=
          release.unit_sign !== originRelease.unit_sign
            ? +this.unitChange(release.unit_sign, +x.success_quantity)
            : +x.success_quantity;

        if (
          release.color_list[x.pur_index].quantity <
          release.sales.color_list[x.pur_index].success_quantity
        )
          release.sales.color_list[x.pur_index].success_quantity =
            release.color_list[x.pur_index].quantity;
      });

      if (this.$store.state.selectStock.length) {
        release.sales.color_list.forEach((x) => {
          const stock = this.$store.state.selectStock.find(
            (y) => y.id === x.indexColorName,
          );
          stock.remain_quantity = x.success_quantity;
          saveStock.push(x);
        });
        this.$store.commit("setSaveStock", saveStock);
      }
    },

    cancelAppearanceCacul(order) {
      const appearance = order.appearance.find(
        (x) => x.id === this.requestForm.appearance,
      );
      const colorList = this.requestForm.sales.color_list;

      appearance.color_list.forEach((x, i) => {
        x.remaing_quantity += colorList[i].success_quantity;
        x.remaing_fail_quantity += colorList[i].fail_quantity;
        x.release_quantity = x.sucess_quantity - x.remaing_quantity;
        x.release_fail_quantity = x.fail_quantity - x.remaing_fail_quantity;
      });
    },

    async cancelPreReleaseCacul(order) {
      const saveStock = [];
      const release = order.release.find(
        (x) => x.id === this.requestForm.release,
      );
      await this.$store.dispatch("FIND_STOCK_DATA", release.id);

      this.requestForm.sales.color_list.forEach((x) => {
        release.sales.color_list[x.pur_index].success_quantity +=
          release.unit_sign !== this.requestForm.unit_sign
            ? +this.unitChange(release.unit_sign, +x.success_quantity)
            : +x.success_quantity;

        if (
          release.color_list[x.pur_index].quantity <
          release.sales.color_list[x.pur_index].success_quantity
        )
          release.sales.color_list[x.pur_index].success_quantity =
            release.colorList[x.pur_index].quantity;
      });

      if (this.$store.state.selectStock.length) {
        release.sales.color_list.forEach((x) => {
          const stock = this.$store.state.selectStock.find(
            (y) => y.id === x.indexColorName,
          );
          stock.remain_quantity = x.success_quantity;
          saveStock.push(stock);
        });
        this.$store.commit("setSaveStock", saveStock);
      }
    },

    releaseSurchargeCheck(order) {
      const release = order.release.find((x) => x.id === this.requestForm.id);
      const manufacture = order.manufacture.find(
        (x) => x.id === release.manufacture,
      );
      manufacture.color_list = manufacture.color_list.map((x, i) => {
        x.indexColorName = x.indexColorName
          ? x.indexColorName
          : `${i + 1}_${x.name}`;
        return x;
      });

      if (manufacture.surcharge_unit && release.purchase.surcharge_price)
        manufacture.surcharge_unit.is_used = false;

      release.purchase.color_list.forEach((x) => {
        if (x.color_surcharge_price) {
          const doc = x.indexColorName
            ? manufacture.color_list.find(
                (y) => y.indexColorName === x.indexColorName,
              )
            : manufacture.color_list.find((y) => y.name === x.name);
          doc.color_surcharge_price.is_used = false;
        }
      });
    },

    surchargeCheck(beforPurchase, newPurchase, id, manufactures) {
      const beForChangeColor = [];
      const newChangeColor = [];
      const manufacture = manufactures.find((x) => x.id === id);

      beforPurchase.color_list.forEach((x) => {
        if (x.color_surcharge_price) {
          const newColor = newPurchase.color_list.find(
            (y) => x.indexColorName === y.indexColorName,
          );
          if (!newColor) beForChangeColor.push(x);
        }
      });

      newPurchase.color_list.forEach((x) => {
        if (x.color_surcharge_price) {
          const newColor = beforPurchase.color_list.find(
            (y) => x.indexColorName === y.indexColorName,
          );
          if (!newColor) newChangeColor.push(x);
        }
      });

      if (beForChangeColor.length) {
        beForChangeColor.forEach((x) => {
          const manufactureColor = manufacture.color_list.find(
            (y) => x.indexColorName === y.indexColorName,
          );
          if (manufactureColor)
            manufactureColor.color_surcharge_price.is_used = false;
        });
      }

      if (newChangeColor.length) {
        newChangeColor.forEach((x) => {
          const manufactureColor = manufacture.color_list.find(
            (y) => x.indexColorName === y.indexColorName,
          );
          if (manufactureColor)
            manufactureColor.color_surcharge_price.is_used = true;
        });
      }
    },

    // 외관, 선매입 수량 계산 끝 =================================
    //클레임 저장시 분별로직
    claimSaveTypeCheck(beforeClaim, order, saveClaim, saveType) {
      // before클레임이 기존의 폼
      const target = this.$store.state.requestForm.formType;

      const beforeClient =
        target === "arise" ? beforeClaim.arise_client : beforeClaim.pass_client;
      const afterClient =
        target === "arise"
          ? "" + saveClaim.arise_client
          : "" + saveClaim.pass_client;
      const beforePrice =
        target === "arise" ? beforeClaim.arise_price : beforeClaim.pass_price;
      const afterPrice =
        target === "arise" ? saveClaim.arise_price : saveClaim.pass_price;
      const beforeMonth =
        target === "arise"
          ? dayjs(beforeClaim.arise_month).format("YYYY-MM")
          : dayjs(beforeClaim.pass_month).format("YYYY-MM");
      const afterMonth =
        target === "arise"
          ? dayjs(saveClaim.arise_month).format("YYYY-MM")
          : dayjs(saveClaim.pass_month).format("YYYY-MM");

      const beforeType =
        target === "arise" ? beforeClaim.arise_type : beforeClaim.pass_type;
      const afterType =
        target === "arise" ? saveClaim.arise_type : saveClaim.pass_type;
      const beforeDetail =
        target === "arise" ? beforeClaim.arise_detail : beforeClaim.pass_detail;
      const afterDetail =
        target === "arise" ? saveClaim.arise_detail : saveClaim.pass_detail;
      const beforeEscPrice =
        target === "arise"
          ? beforeClaim.arise_eschamge
          : beforeClaim.pass_eschamge;
      const afterEscPrice =
        target === "arise" ? saveClaim.arise_eschamge : saveClaim.pass_eschamge;
      if (
        (beforeClaim.order === "미선택" || !beforeClaim.order) &&
        saveClaim.order
      )
        saveType.newOrder = true;
      if (
        (beforeClaim.order || beforeClaim.order !== "미선택") &&
        order._id !== saveClaim.order
      )
        saveType.orderChange = true;
      if (
        beforeClient !== afterClient ||
        beforePrice !== afterPrice ||
        beforeMonth !== afterMonth ||
        beforeEscPrice !== afterEscPrice ||
        beforeDetail !== afterDetail
      )
        saveType.modifyRelease = true;
      if (
        beforeType !== 1 &&
        beforeType !== 4 &&
        beforeType !== afterType &&
        afterType !== 1 &&
        afterType !== 4
      )
        saveType.type = true;
      if ((beforeType === 1 || beforeType === 4) && beforeType !== afterType)
        saveType.addType = true;
      if (beforeType !== 1 && (afterType === 1 || afterType === 4))
        saveType.subType = true;
      if (order.release) {
        const beforeRelease = order.release.find(
          (x) =>
            x.claim ===
            (target === "arise" ? saveClaim.arise_id : saveClaim.pass_id),
        );
        const releaseType = beforeType === 2 ? "purchase" : "sales";
        if (beforeRelease && beforeRelease[releaseType].status === "00")
          saveType.releaseEnd = true;
      }
    },
    async close() {
      this.$store.commit("setRequestForm");
      this.$store.commit("setApprovalForm");
      this.$store.commit("setSelectOrder");
      this.$store.commit("setRequestDialog");

      await this.$store.dispatch("PARSE_ROUTE_API_DATA_CALL");
    },

    statusKind(status) {
      return status === "20"
        ? "승인요청"
        : status === "50"
        ? "수정요청"
        : status === "10"
        ? "삭제 요청"
        : status === "60"
        ? "마감요청"
        : status === "90" || status === "10"
        ? "취소요청"
        : "";
    },

    numFromat(num) {
      return 10 > num ? `00${num}` : 100 > num ? `0${num}` : `${num}`;
    },

    btdDisabled(name) {
      const data = this.$store.state.requestList.find((x) => x.name === name);
      return !data;
    },

    // porgress 수정
    progressLogMake(data, kind) {
      return kind === 7
        ? `${data.place_manufacture.name}(~${data.date_payment})`
        : kind === 11
        ? `${data.inspection.name}`
        : kind === 9 || kind === 15
        ? `${data.place_forward.name} -> ${
            data.place_sales ? data.place_sales.name : "미지정"
          }`
        : `${data.place_forward.name} -> ${
            data.place_receive ? data.place_receive.name : "미지정"
          }`;
    },

    async stockMake() {
      if (this.requestForm.status === "50") await this.deleteStockMake();

      const release = this.copyData(this.requestForm);
      release.color_list = this.requestForm.color_list.filter((x) => x.name);
      release.status = "40";
      release.place_forward = this.requestForm.place_forward._id;

      this.$store.dispatch("SET_NEW_STOCK_DATA", {
        release,
        item: this.requestForm.item.name,
      });
    },

    async deleteStockMake() {
      await this.$store.dispatch("FIND_STOCK_DATA", this.requestForm.id);
      this.$store.commit(
        "setDeleteStock",
        this.copyData(this.$store.state.selectStock),
      );

      this.$store.commit("setSelectStock");
    },

    async stockCompanion() {
      await this.$store.dispatch("FIND_STOCK_DATA", this.requestForm.id);
      this.$store.commit(
        "setSaveStock",
        this.$store.state.selectStock.map((x) => {
          const color = x;
          color.status = "40";
          return color;
        }),
      );

      this.$store.commit("setSelectStock");
    },
  },
  created() {
    if (this.$store.state.auth_company) {
      this.$store.dispatch("SET_ITEMLIST");
      this.$store.dispatch("SET_CTYPELIST");
      this.$store.dispatch("SET_USERLIST");
      this.$store.dispatch("SET_CLIENTLIST");
    }
  },
};
