<template>
  <div class="Announcement">
    <div class="AnnouncementButton">
      <v-btn tile text icon small @click="moveText('left')">
        <v-icon color="black">mdi-chevron-left</v-icon>
      </v-btn>
    </div>

    <div class="AnnouncementBody">
      <span>
        {{ announcementText }}
      </span>
    </div>

    <div class="AnnouncementButton">
      <v-btn tile text icon small @click="moveText('right')">
        <v-icon color="black"> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      announcementText: "",
      textList: [
        "2024.12.09 - 폰트크기 설정 추가",
        "2024.11.22 - 신규 가입 형태 변경",
        "2024.11.22 - 설정페이지 리뉴얼",
        "2024.11.15 - 외상금 vat 구분 추가",
        "2024.11.15 - 프린터 폼 변경추가",
      ],
      textIndex: 0,
      isTimer: true,
    };
  },
  methods: {
    setAnnouncement() {
      const timer = setInterval(() => {
        if (this.isTimer) this.setText();
        else clearInterval(timer);
      }, 3000);
    },
    setText() {
      const textLength = this.textList.length;
      const index = this.textIndex + 1;
      if (index < textLength && index !== textLength) {
        this.announcementText = this.textList[index];
        this.textIndex = index;
      } else {
        this.announcementText = this.textList[0];
        this.textIndex = 0;
      }
    },
    moveText(kind) {
      this.isTimer = false;
      const textLength = this.textList.length;
      const index = this.textIndex + (kind === "right" ? +1 : -1);

      this.textIndex =
        index < 0
          ? textLength - 1
          : textLength < index || textLength === index
          ? 0
          : index;

      this.announcementText = this.textList[this.textIndex];
    },
    startAnnouncement() {
      this.isTimer = true;
      this.setAnnouncement();
    },
  },
  created() {
    this.announcementText = this.textList[0];
    this.setAnnouncement();
  },
};
</script>
