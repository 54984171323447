<template>
  <footer class="footer">
    <div class="footerContainer">
      <div class="footerLeft">
        <strong>ⓒSoftMani all rights reserved.</strong>
      </div>

      <div class="footerRight">
        <div class="Announcement">
          <div class="AnnouncementBody">
            <Announcement class="text-center subtitle-1" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Announcement from "@/components/pages/common/Announcement.vue";
export default {
  components: {
    Announcement,
  },
};
</script>
