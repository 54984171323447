<template>
  <v-dialog
    :value="dialog_cacution"
    @click:outside="$store.state.isDialog = false"
    @keydown.esc="$store.state.isDialog = false"
    @keydown.enter="saveEditForm"
    max-width="1800px"
  >
    <v-card>
      <v-card-title> 알림 </v-card-title>
      <v-card-text>
        <div class="cacutionSection">
          <div>
            <div class="searchArea">
              <div>
                <v-autocomplete dense hide-details solo label="발주처" />
              </div>

              <div class="">
                <v-autocomplete dense hide-details solo label="계산서발행처" />
              </div>
              <div class="">
                <v-autocomplete dense hide-details solo label="아이템" />
              </div>
              <div class="searchBtnArea">
                <v-btn large color="#3A3A3A ">
                  <v-icon color="white" size="25"> mdi-magnify</v-icon>
                </v-btn>
              </div>
              <div class="">
                <v-autocomplete dense hide-details solo label="생산처" />
              </div>
              <div class="">
                <v-autocomplete dense hide-details solo label="생산처 아이템" />
              </div>
              <div class="">
                <v-autocomplete dense hide-details solo label="담당자" />
              </div>
            </div>

            <div class="d-flex justify-end pb-1" style="gap: 10px">
              <div class="cacutionColor" />
              <div class="cacutionSubText">납기 14일이내</div>
              <div class="dangerColor" />
              <div class="cacutionSubText">납기 7일이내</div>
            </div>

            <div class="cacutionGrid">
              <div class="cacutionHeader">오더</div>
              <div class="cacutionHeader">상태</div>
              <div class="cacutionHeader">기한</div>
              <div class="cacutionBody">
                <div class="cacutionOrder">
                  <div class="">
                    <div class="cacutionTitleGrid">
                      <div class="">
                        <span class="cacutionTitle">23ss-101</span>
                      </div>
                      <div style="margin-left: auto">
                        <div class="d-flex">
                          <span class=""> 재고 : </span>
                          <span class="red--text" style="margin-left: auto">
                            200y
                          </span>
                        </div>
                        <div class="d-flex">
                          <span class="">출고량 : </span>
                          <span class="green--text" style="margin-left: auto">
                            700y
                          </span>
                        </div>
                      </div>
                    </div>

                    <v-divider class="mt-3" />
                    <div class="buyerGridBody">
                      <div class="cacutionSubText">발주처 : 하나패션</div>
                      <div class="cacutionSubText">
                        아이템 : FNS AZ03 2703-1
                      </div>
                      <div class="cacutionSubText">생산처 : 필나인 대구</div>
                      <div class="cacutionSubText">생산 아이템 : TK-485</div>
                      <div class="cacutionSubText">담당자 : 김지균</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="cacutionBody">
                <div class="cautionStatus">
                  <div class="cacutionStatTitle">B/T 의뢰</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">B/T 전달</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">B/T 컨펌</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">MAIN 전달</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">MAIN 컨펌</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">가공의뢰</div>
                  <div class="cacutionStatBody">1</div>
                  <div class="cacutionStatTitle">매입출고</div>
                  <div class="cacutionStatBody">1</div>
                  <div class="cacutionStatTitle">매출출고</div>
                  <div class="cacutionStatNow">0</div>
                  <div class="cacutionStatTitle">이화학 의뢰</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">이화학 결과</div>
                  <div class="cacutionStatBody">0</div>
                </div>
              </div>
              <div class="cacutionBody">
                <div class="cautionDate">
                  <div class="cacutionStatTitle">(주)선아섬유 | 가공의뢰</div>
                  <div class="cacutionStatBody danger">(~2023-08-07)</div>
                </div>
                <div class="cautionDate">
                  <div class="cacutionStatTitle">
                    (주)선아섬유 -> 지정검사소없음 | 검사의뢰
                  </div>
                  <div class="cacutionStatBody cacution">(~2023-08-14)</div>
                </div>
                <div class="cautionDate">
                  <div class="cacutionStatTitle">
                    (주)선아섬유 -> (주)다니엘인터패션 | 매입출고
                  </div>
                  <div class="cacutionStatBody">(~2023-08-31)</div>
                </div>
              </div>
              <div
                class="cacutionBody"
                style="display: flex; flex-direction: column"
              >
                <div class="cacutionOrder">
                  <div>
                    <div class="cacutionTitleGrid">
                      <div class="">
                        <span class="cacutionTitle">23ss-101</span>
                      </div>
                      <div style="margin-left: auto">
                        <div class="d-flex">
                          <span class=""> 재고 : </span>
                          <span class="red--text" style="margin-left: auto">
                            200y
                          </span>
                        </div>
                        <div class="d-flex">
                          <span class="">출고량 : </span>
                          <span class="green--text" style="margin-left: auto">
                            700y
                          </span>
                        </div>
                      </div>
                    </div>
                    <v-divider class="mt-3" />
                    <div class="buyerGridBody">
                      <div class="cacutionSubText">발주처 : 페리게이츠</div>
                      <div class="cacutionSubText">아이템 : FNS HS 2201</div>
                      <div class="cacutionSubText">생산처 : 두성텍스타일</div>
                      <div class="cacutionSubText">생산 아이템 : HS - 2201</div>
                      <div class="cacutionSubText">담당자 : 심준</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cacutionBody">
                <div class="cautionStatus">
                  <div class="cacutionStatTitle">B/T 의뢰</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">B/T 전달</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">B/T 컨펌</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">MAIN 전달</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">MAIN 컨펌</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">가공의뢰</div>
                  <div class="cacutionStatBody">2</div>
                  <div class="cacutionStatTitle">매입출고</div>
                  <div class="cacutionStatBody">2</div>
                  <div class="cacutionStatTitle">매출출고</div>
                  <div class="cacutionStatNow">0</div>
                  <div class="cacutionStatTitle">이화학 의뢰</div>
                  <div class="cacutionStatBody">0</div>
                  <div class="cacutionStatTitle">이화학 결과</div>
                  <div class="cacutionStatBody">0</div>
                </div>
              </div>
              <div class="cacutionBody">
                <div class="cautionDate">
                  <div class="cacutionStatTitle">다랭이 | 가공의뢰</div>
                  <div class="cacutionStatBody">(~2023-08-31)</div>
                </div>
                <div class="cautionDate">
                  <div class="cacutionStatTitle">다우로직스 | 가공의뢰</div>
                  <div class="cacutionStatBody">(~2023-08-31)</div>
                </div>
                <div class="cautionDate">
                  <div class="cacutionStatTitle">
                    다랭이 -> 수&빈 | 매입출고
                  </div>
                  <div class="cacutionStatBody">(~2023-09-12)</div>
                </div>
                <div class="cautionDate">
                  <div class="cacutionStatTitle">
                    (주)다랭이 -> 하나패션 | 매입출고
                  </div>
                  <div class="cacutionStatBody">(~2023-09-20)</div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column" style="gap: 15px">
            <div>
              <v-calendar
                ref="calendar"
                locale="kr"
                color="primary"
                style="height: 400px"
                :events="eventTimes"
                :event-overlap-threshold="20"
                event-overlap-mode="column"
                event-height="15"
              >
              </v-calendar>
            </div>
            <div class="calendarInfo">
              <span class="calendarInfoTitle">할 일 목록</span>
              <div class="calendarInfoBody">
                <v-card height="35" class="">
                  <div class="d-flex">
                    <span class="pl-2 d-flex justify-center align-center">
                      1. (주)선아섬유 | 가공의뢰 (~2023-08-07)
                    </span>
                    <div style="margin-left: auto" class="pr-2">
                      <v-avatar :color="color[0]" size="32">
                        <span class="white--text">D-6</span>
                      </v-avatar>
                    </div>
                  </div>
                </v-card>
                <v-card height="35" class="">
                  <div class="d-flex">
                    <span class="d-flex justify-center align-center pl-2">
                      2. (주)선아섬유 -> 지정검사소없음 | 검사의뢰 (~2023-08-14)
                    </span>
                    <div style="margin-left: auto" class="pr-2">
                      <v-avatar :color="color[1]" size="32">
                        <span class="white--text">D-13</span>
                      </v-avatar>
                    </div>
                  </div>
                </v-card>
                <v-card height="35" class="">
                  <div class="d-flex">
                    <span class="d-flex justify-center align-center pl-2">
                      3. (주)선아섬유 -> (주)다니엘인터패션 | 매입출고 |
                      검사의뢰 (~2023-08-31)
                    </span>
                    <div style="margin-left: auto" class="pr-2">
                      <v-avatar :color="color[2]" size="32">
                        <span class="white--text">D-29</span>
                      </v-avatar>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn
          color="blue darken-1"
          text
          @click="$store.state.isDialog = false"
        >
          취소
        </v-btn>
        <v-btn color="blue darken-1" text type="number"> 변경 </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      color: ["red", "blue", "orange", "green"],
      eventTimes: [],
    };
  },
  computed: {
    dialog_cacution() {
      return this.$store.state.isDialog === true &&
        this.$store.state.isKind === "dialog_cacution"
        ? true
        : false;
    },
  },
  methods: {},
  mounted() {
    this.eventTimes.push(
      {
        name: "23ss-101 선아섬유 가공의뢰",
        start: new Date(),
        end: new Date(Date.now() + 5 * 24 * 60 * 60 * 1000),
        color: this.color[0],
        timed: false,
      },
      {
        name: "23ss-101 선아섬유 -> 지정검사소없음 검사의뢰",
        start: new Date(),
        end: new Date(Date.now() + 12 * 24 * 60 * 60 * 1000),
        color: this.color[1],
        timed: false,
      },
      {
        name: "23ss-101 선아섬유 -> (주)다니엘인터패션",
        start: "2023-08-31",
        color: this.color[2],
        timed: false,
      },
    );
  },
};
</script>
