<template>
  <div>
    <h2 class="pb-4">{{ title }}</h2>
    <div
      class="pr-3 pl-3"
      style="display: grid; grid-template-columns: 4fr 6fr"
    >
      <div
        style="
          display: grid;
          grid-template-rows: repeat(22, minmax(auto, 1.9rem));
          grid-template-columns: 4fr 6fr;
        "
      >
        <div class="text-bold releaseGridBorderLeft">ORDER NO.</div>
        <div class="releaseGridBorder">
          {{ manufacture.order }}
        </div>

        <!-- leftForm 수신처 -->
        <div class="text-bold releaseGridBorderLeft">수신처</div>
        <div class="releaseGridBorder">
          <div>
            {{
              manufacture.place_manufacture ? manufacture.place_manufacture : ""
            }}
          </div>
        </div>

        <!-- leftForm 발신인 -->
        <div class="text-bold releaseGridBorderLeft">발신인</div>
        <div class="releaseGridBorder">
          {{ manufacture.liaison ? manufacture.liaison : "" }}
        </div>

        <!-- leftForm ITEM NO -->
        <div class="text-bold releaseGridBorderLeft">ITEM NO</div>
        <div class="releaseGridBorder">
          <div>
            {{ manufacture.item ? manufacture.item : "" }}
          </div>
        </div>

        <!-- leftForm 가 공 -->
        <div class="text-bold releaseGridBorderLeft">가 공</div>
        <div class="releaseGridBorder">
          <div>
            {{ manufacture.post_processing ? manufacture.post_processing : "" }}
          </div>
        </div>

        <!-- leftForm 폭 -->
        <div class="text-bold releaseGridBorderLeft">폭</div>
        <div class="releaseGridBorder">
          <div>
            {{ manufacture.width ? manufacture.width : "" }}
          </div>
        </div>

        <!-- leftForm 축률 -->
        <!-- <div class="text-bold releaseGridBorderLeft">축 률(%)</div> -->
        <!-- <div class="manufactureGridBorder">
          <div
            v-if="manufacture.isOrigin && origin.shrigkage"
            class="originTextRight"
            style="text-align: right"
          >
            {{ origin.shrigkage ? origin.shrigkage + "%" : "" }}
          </div>
          <div
            :class="origin.shrigkage ? 'originText' : ''"
            style="text-align: right"
          >
            {{ manufacture.shrigkage ? manufacture.shrigkage + "%" : "" }}
          </div>
        </div> -->

        <!-- leftForm 수 량 -->
        <div class="text-bold releaseGridBorderLeft">수 량</div>
        <div class="manufactureGridBorder pr-2">
          <div style="text-align: right">
            {{
              ((manufacture.total_quantity_sum
                ? manufacture.total_quantity_sum
                : "") +
                manufacture.unit_sign)
                | makeComma
            }}
          </div>
        </div>

        <!-- leftForm 출고납기 -->
        <div class="text-bold releaseGridBorderLeft">출고납기</div>
        <div class="releaseGridBorder">
          <div>
            {{ manufacture.date_payment ? manufacture.date_payment : "" }}
          </div>
        </div>

        <!-- leftForm 매입단가 -->
        <div class="text-bold releaseGridBorderLeft">매입단가</div>
        <div class="manufactureGridBorder">
          <div class="pr-2">
            <div
              :class="
                manufacture.currency_sign === '₩'
                  ? 'requestUnitPrice'
                  : 'requestUnitPriceExchange'
              "
            >
              <div style="text-align: right">
                {{
                  (manufacture.currency_sign +
                    (manufacture.unit_price ? manufacture.unit_price : ""))
                    | makeComma
                }}
              </div>

              <div
                v-if="manufacture.currency_sign !== '₩'"
                style="text-align: right; padding-right: 4px"
              >
                (
                {{
                  ("₩" + (manufacture.eschamge ? manufacture.eschamge : 0))
                    | makeComma
                }})
              </div>
            </div>
          </div>
        </div>

        <!-- leftForm COLOR별 SAMPLE -->
        <div class="text-bold releaseGridBorderLeft">COLOR별 SAMPLE</div>
        <div class="releaseGridBorder">
          <div>
            {{
              manufacture.quantity_by_color ? manufacture.quantity_by_color : ""
            }}
          </div>
        </div>

        <!-- leftForm 검사소 -->
        <div class="text-bold releaseGridBorderLeft">검사소</div>
        <div class="releaseGridBorder">
          <div>
            {{ manufacture.inspection ? manufacture.inspection : "" }}
          </div>
        </div>

        <!-- leftForm Surcharge (제직, 단위 당) -->
        <div class="text-bold releaseGridBorderLeft">
          Surcharge (제직, 단위 당)
        </div>
        <div class="releaseGridBorder">
          <div>
            {{
              (manufacture.sucharge_price
                ? manufacture.currency_sign + manufacture.sucharge_price
                : "") | makeComma
            }}
          </div>
        </div>

        <!-- leftForm Surcharge (제직, 건 당) -->
        <div class="text-bold releaseGridBorderLeft">
          Surcharge (제직, 건 당)
        </div>
        <div class="releaseGridBorder">
          <div>
            {{
              (manufacture.surcharge_unit
                ? manufacture.currency_sign + manufacture.surcharge_unit
                : "") | makeComma
            }}
          </div>
        </div>

        <!-- leftForm STYLE List -->
        <div class="text-bold releaseGridBorderLeft" style="grid-row: span 4">
          STYLE NO
        </div>
        <div
          v-if="manufacture.style_list.length"
          class="manufactureStyleListRight"
        >
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
            style="min-width: 100%"
          >
            <div
              v-for="item in manufacture.style_list"
              :key="item"
              style="display: grid; width: 100%"
            >
              <div class="manufactureStyleList">
                <div>
                  {{ item }}
                </div>
              </div>
            </div>
          </overlay-scrollbars>
        </div>
        <div
          v-else
          class="manufactureStyleListRight"
          style="border-bottom: thin solid rgba(0, 0, 0, 1)"
        />

        <div
          class="text-bold manufactureGridBorderLeft"
          style="grid-column: span 2"
        >
          확 인 사 항
        </div>
        <div
          class="releaseGridBorderBotoom"
          style="grid-column: span 2; grid-row: span 4"
        >
          <v-textarea
            v-model="manufacture.confirmation"
            rows="5"
            no-resize
            :outlined="false"
            clear-icon="mdi-close-circle"
            style="overflow-x: hidden; overflow-y: hidden"
            hide-details
            readonly
            :ref="`inputTab18`"
            class="lefted-input"
            tabindex="18"
          />
        </div>
      </div>
      <div
        style="
          display: grid;
          grid-template-rows: repeat(22, minmax(auto, 1.9rem));
          grid-template-columns: 2fr 2fr 1.5fr 2fr 2fr;
        "
      >
        <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
          COLOR
        </div>
        <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
          수량
        </div>
        <!-- <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
          수량
          <br />(축률)
        </div> -->
        <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
          수량(샘플)
        </div>
        <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
          Surcharge
          <br />
          (염색, 단위당)
        </div>
        <div class="text-bold releaseColorListHeader" style="grid-row: span 2">
          Surcharge
          <br />
          (염색, Color당)
        </div>
        <div
          style="
            display: grid;
            overflow-y: overlay;
            grid-row: span 15;
            grid-column: span 6;
          "
        >
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
            style="min-width: 100%"
          >
            <div
              v-for="(color, i) in 15"
              :key="color._id"
              style="
                display: grid;
                grid-template-columns: 2fr 2fr 1.5fr 2fr 2fr;
                height: 1.9rem;
              "
            >
              <!-- RightColorList 컬러 -->
              <div class="requestColorListBody">
                <div>
                  {{
                    manufacture.color_list[i]
                      ? manufacture.color_list[i].name
                      : ""
                  }}
                </div>
              </div>

              <!-- RightColorList 컬러수량 -->
              <div class="originRequestColorListBody">
                <div>
                  {{
                    (manufacture.color_list[i] &&
                    manufacture.color_list[i].quantity
                      ? manufacture.color_list[i].quantity +
                        manufacture.unit_sign
                      : "") | makeComma
                  }}
                </div>
              </div>
              <!-- <div class="originRequestColorListBody">
                <div
                  v-if="
                    manufacture.isOrigin &&
                    origin.color_list[i].quantity_shrigkage !==
                      color.quantity_shrigkage &&
                    origin.color_list[i].quantity_shrigkage
                  "
                  class="colorListOriginTextRight"
                >
                  {{
                    (origin.color_list[i].quantity_shrigkage
                      ? origin.color_list[i].quantity_shrigkage + origin.unit_sign
                      : "") | makeComma
                  }}
                </div>
                <div
                  :class="
                    origin.color_list.length &&
                    origin.color_list[i].quantity_shrigkage !==
                      color.quantity_shrigkage
                      ? 'colorListOriginText'
                      : ''
                  "
                >
                  {{
                    (color.quantity_shrigkage
                      ? color.quantity_shrigkage + manufacture.unit_sign
                      : "") | makeComma
                  }}
                </div>
              </div> -->

              <!-- RightColorList 컬러샘플수량 -->
              <div class="originRequestColorListBody">
                <div>
                  {{
                    (manufacture.color_list[i] &&
                    manufacture.color_list[i].quantity_sample
                      ? manufacture.color_list[i].quantity_sample +
                        manufacture.unit_sign
                      : "") | makeComma
                  }}
                </div>
              </div>

              <!-- RightColorList Surcharge(염색, 단윈당) -->
              <div class="originRequestColorListBody">
                <div>
                  {{
                    manufacture.color_list[i] &&
                    manufacture.color_list[i].color_surcharge_unit
                      ? manufacture.currency_sign +
                        manufacture.color_list[i].color_surcharge_unit
                      : "" | makeComma
                  }}
                </div>
              </div>

              <!-- RightColorList Surcharge(염색, Color당) -->
              <div class="originRequestColorListBody">
                <div>
                  {{
                    manufacture.color_list[i]
                      ? manufacture.color_list[i].color_surcharge_price
                        ? manufacture.currency_sign +
                          manufacture.color_list[i].color_surcharge_price.price
                        : ""
                      : "" | makeComma
                  }}
                </div>
              </div>
            </div>
          </overlay-scrollbars>
        </div>
        <div class="text-bold releaseGridBorder" style="grid-column: span 6">
          주 의 사 항
        </div>
        <div
          class="releaseGridBorderBotoomRight"
          style="grid-column: span 6; grid-row: span 4"
        >
          <v-textarea
            v-model="manufacture.precautions"
            rows="4"
            no-resize
            :outlined="false"
            clear-icon="mdi-close-circle"
            style="overflow-x: hidden; overflow-y: hidden"
            hide-details
            readonly
            class="lefted-input"
            :ref="`inputTab18`"
            tabindex="18"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      requestData: "getRequestForm",
    }),
    manufacture() {
      return this.requestData.requestDoc;
    },
    totalQuantity() {
      return this.manufacture.color_list.length
        ? this.manufacture.color_list.reduce(
            (a, c) => a + (c.name ? +c.quantity : 0),
            0,
          )
        : 0;
    },
    title() {
      return `가공의뢰서 (${
        this.manufacture.status === "20"
          ? "승인"
          : this.manufacture.status === "50"
          ? "수정"
          : this.manufacture.status === "40"
          ? "승인"
          : "취소"
      }) `;
    },

    origin() {
      return this.manufacture.origin
        ? this.manufacture.origin
        : { color_list: [] };
    },
  },
  data() {
    return {
      // isOrigin: true,
    };
  },
  methods: {
    colorCompare(type, i) {
      const requestColor =
        this.manufacture.color_list[i] && this.manufacture.color_list[i][type];
      const originColor =
        this.manufacture.origin.color_list[i] &&
        this.manufacture.origin.color_list[i][type];

      return (originColor !== undefined || requestColor !== undefined) &&
        originColor !== requestColor
        ? true
        : false;
    },
  },
};
</script>

<style></style>
