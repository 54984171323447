var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pr-3 pl-3"},[_c('h2',{},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"checkLabelArea"},[(_vm.order.status === '50')?_c('v-checkbox',{attrs:{"hide-details":""},on:{"click":function($event){_vm.order.isOrigin = !_vm.order.isOrigin}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"checkboxLabel"},[_vm._v("기존 데이터만 보기")])]},proxy:true}],null,false,2560933483)}):_vm._e()],1),_c('div',{staticClass:"orderRequestLeft"},[_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("ORDER NO")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[_vm._v(" "+_vm._s(_vm.order.order ? _vm.order.order : "")+" ")]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("BUYER ORDER NO")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(_vm.order.isOrigin && _vm.order.buyer_order !== _vm.origin.buyer_order)?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.buyer_order ? _vm.origin.buyer_order : "")+" ")]):_vm._e(),_c('div',{class:_vm.order.buyer_order !== _vm.origin.buyer_order && _vm.origin.buyer_order
            ? 'originText'
            : ''},[_vm._v(" "+_vm._s(_vm.order.buyer_order ? _vm.order.buyer_order : "")+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("구매처")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(_vm.order.isOrigin && _vm.order.client !== _vm.origin.client)?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.client ? _vm.origin.client : "")+" ")]):_vm._e(),_c('div',{class:_vm.order.status !== '20' &&
          _vm.order.client !== _vm.origin.client &&
          _vm.origin.client
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.client ? _vm.order.client : "")+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("오더 유형")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(_vm.order.isOrigin && _vm.order.order_kind !== _vm.origin.order_kind)?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.order_kind ? _vm.origin.order_kind : "")+" ")]):_vm._e(),_c('div',{class:_vm.order.status !== '20' &&
          _vm.origin.order_kind &&
          _vm.order.order_kind !== _vm.origin.order_kind
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.order_kind)+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("복종")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(_vm.order.isOrigin && _vm.order.ctype !== _vm.origin.ctype)?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.ctype && _vm.origin.ctype ? _vm.origin.ctype : "선택안함")+" ")]):_vm._e(),_c('div',{class:_vm.order.status !== '20' &&
          _vm.origin.ctype &&
          _vm.order.ctype !== _vm.origin.ctype
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.ctype && _vm.order.ctype ? _vm.order.ctype : "선택안함")+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("성별")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(_vm.order.isOrigin && _vm.order.gender !== _vm.origin.gender)?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.order.gender)+" ")]):_vm._e(),_c('div',{class:_vm.order.status !== '20' &&
          _vm.origin.gender &&
          _vm.order.gender !== _vm.origin.gender
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.gender)+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("아이템(자사)")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(_vm.order.isOrigin && _vm.order.item !== _vm.origin.item)?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.item ? _vm.origin.item : "")+" ")]):_vm._e(),_c('div',{class:_vm.origin.item && _vm.order.item !== _vm.origin.item
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.item ? _vm.order.item : "")+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("생산처")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(_vm.order.isOrigin && _vm.order.production !== _vm.origin.production)?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.production ? _vm.origin.production : "")+" ")]):_vm._e(),_c('div',{class:_vm.origin.production && _vm.order.production !== _vm.origin.production
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.production ? _vm.order.production : "")+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("아이템(생산처)")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(
          _vm.order.isOrigin && _vm.order.productionItem !== _vm.origin.productionItem
        )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.productionItem ? _vm.origin.productionItem : "")+" ")]):_vm._e(),_c('div',{class:_vm.origin.productionItem &&
          _vm.order.productionItem !== _vm.origin.productionItem
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.productionItem ? _vm.order.productionItem : "")+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("담당자")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[_vm._v(" "+_vm._s(_vm.order.liaison ? _vm.order.liaison : "")+" ")]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("매입 구분")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(
          _vm.order.isOrigin &&
          _vm.order.purchase_classification !== _vm.origin.purchase_classification
        )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.purchase_classification ? _vm.origin.purchase_classification : "")+" ")]):_vm._e(),_c('div',{class:_vm.origin.purchase_classification &&
          _vm.order.purchase_classification !== _vm.origin.purchase_classification
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.purchase_classification)+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("매출 구분")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(
          _vm.order.isOrigin &&
          _vm.order.sales_classification !== _vm.origin.sales_classification
        )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.sales_classification ? _vm.origin.sales_classification : "")+" ")]):_vm._e(),_c('div',{class:_vm.origin.sales_classification &&
          _vm.order.sales_classification !== _vm.origin.sales_classification
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.purchase_classification)+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("통화 단위")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(_vm.order.isOrigin && _vm.order.eschamge_sign !== _vm.origin.eschamge_sign)?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.eschamge_sign ? _vm.origin.eschamge_sign : "")+" ")]):_vm._e(),_c('div',{class:_vm.origin.eschamge_sign && _vm.order.eschamge_sign !== _vm.origin.eschamge_sign
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm.order.eschamge_sign)+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft"},[_vm._v("매출단가")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft"},[(
          _vm.order.isOrigin && _vm.order.sales_unit_price !== _vm.origin.sales_unit_price
        )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.origin.sales_unit_price ? ("" + (_vm.origin.eschamge_sign) + (_vm.origin.sales_unit_price)) : ""))+" ")]):_vm._e(),_c('div',{class:_vm.order.status !== '20' &&
          _vm.origin.sales_unit_price &&
          _vm.order.sales_unit_price !== _vm.origin.sales_unit_price
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(("" + (_vm.order.sales_unit_price ? ("" + (_vm.order.eschamge_sign) + (_vm.order.sales_unit_price)) : ""))))+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft requestBorderBottom"},[_vm._v(" 매출 Surcharge ")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft requestBorderBottom"},[(
          _vm.order.isOrigin && _vm.order.sales_surcharge !== _vm.origin.sales_surcharge
        )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.origin.sales_surcharge ? ("" + (_vm.origin.eschamge_sign) + (_vm.origin.sales_surcharge)) : ""))+" ")]):_vm._e(),_c('div',{class:_vm.origin.sales_surcharge &&
          _vm.order.sales_surcharge !== _vm.origin.sales_surcharge
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.order.sales_surcharge ? ("" + (_vm.order.eschamge_sign) + (_vm.order.sales_surcharge)) : ""))+" ")])]),_c('div',{staticClass:"text-bold releaseGridBorderLeft requestBorderBottom"},[_vm._v(" 선수금 ")]),_c('div',{staticClass:"text-bold requestOrderGridBorderLeft requestBorderBottom"},[(
          _vm.order.isOrigin &&
          _vm.origin.advance_pay &&
          _vm.order.advance_pay !== _vm.origin.advance_pay
        )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.origin.advance_pay ? ("" + (_vm.origin.eschamge_sign) + (_vm.origin.advance_pay)) : ""))+" ")]):_vm._e(),_c('div',{class:_vm.origin.advance_pay && _vm.order.advance_pay !== _vm.origin.advance_pay
            ? 'originText red--text'
            : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(_vm.order.advance_pay ? ("" + (_vm.order.eschamge_sign) + (_vm.order.advance_pay)) : ""))+" ")])]),_c('div',{staticClass:"text-bold requestOrderRemark",staticStyle:{"border-bottom":"thin solid rgba(0, 0, 0, 1)"}},[_c('v-textarea',{staticClass:"lefted-input pt-1",class:_vm.order.remark !== _vm.origin.remark ? 'red--text' : '',attrs:{"rows":"7","dense":"","label":"비고","hide-details":"","disabled":"","no-resize":""},model:{value:(_vm.order.remark),callback:function ($$v) {_vm.$set(_vm.order, "remark", $$v)},expression:"order.remark"}})],1)]),_c('div',{staticClass:"orderRequestBottomGrid"},[_c('div',{staticClass:"text-bold orderRequestStyle"},[_vm._v("STYLE LIST")]),_c('div',{staticClass:"text-bold orderRequestColor"},[_vm._v("COLOR LIST")]),_c('div',{staticClass:"requestBottomBodyGrid"},[_c('div',[_c('overlay-scrollbars',{ref:"scrollBar",staticStyle:{"border-bottom":"thin solid black"},attrs:{"options":{
            overflowBehavior: {
              x: 'hidden',
            },
          },"defer":""}},[_c('div',{staticStyle:{"width":"100%","max-height":"20vh"}},[_c('div',[(_vm.order.style_list && _vm.order.style_list.length)?_vm._l((_vm.order.style_list),function(style,i){return _c('div',{key:i,staticClass:"orderRequestList"},[_c('div',{staticClass:"requestFlexBody"},[(
                        _vm.order.isOrigin &&
                        _vm.origin.style_list[i] !== style &&
                        _vm.origin.style_list[i]
                      )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.style_list[i])+" ")]):_vm._e(),_c('div',{class:_vm.origin.style_list[i] !== style
                          ? 'originText red--text'
                          : ''},[_vm._v(" "+_vm._s(style)+" ")])])])}):[_c('div',{staticClass:"orderRequestList"})]],2)])])],1)]),_c('div',{staticClass:"requestBottomBodyGrid"},[_c('div',[_c('overlay-scrollbars',{ref:"scrollBar",attrs:{"options":{
            overflowBehavior: {
              x: 'hidden',
            },
          },"defer":""}},[_c('div',{staticStyle:{"width":"100%","min-height":"20vh","max-height":"20vh"}},[(_vm.order.color_list && _vm.order.color_list.length)?[_c('div',{staticClass:"requestColorGrid orderRequestList"},[_c('div',{staticClass:"requestColorBody font-weight-bold"}),_c('div',{staticClass:"\n                    requestColorBody\n                    font-weight-bold\n                    bodyLeftBlackBorder\n                  "},[_vm._v(" 컬러명 ")]),_c('div',{staticClass:"\n                    bodyLeftBlackBorder\n                    requestColorBody\n                    font-weight-bold\n                  "},[_vm._v(" 수량 ")]),_c('div',{staticClass:"\n                    bodyLeftBlackBorder\n                    requestColorBody\n                    font-weight-bold\n                  "},[_vm._v(" 단가 ")]),_c('div',{staticClass:"\n                    bodyLeftBlackBorder\n                    requestColorBody\n                    font-weight-bold\n                  "},[_vm._v(" Surcharge ")])]),_vm._l((_vm.order.color_list),function(color,i){return _c('div',{key:i,staticClass:"orderRequestList"},[_c('div',{staticClass:"requestColorGrid"},[_c('div',{staticClass:"requestColorBody"}),_c('div',{staticClass:"requestColorBody bodyLeftBlackBorder"},[(
                        _vm.order.isOrigin &&
                        _vm.origin.color_list[i] &&
                        _vm.origin.color_list[i].name !== color.name
                      )?_c('div',{staticClass:"originTextRight"},[_vm._v(" "+_vm._s(_vm.origin.color_list[i] ? _vm.origin.color_list[i].name : "")+" ")]):_vm._e(),_c('div',{class:_vm.colorCompare('name', i)
                          ? 'originText red--text  '
                          : ''},[_vm._v(" "+_vm._s(color.name ? color.name : "")+" ")])]),_c('div',{staticClass:"requestColorBodyRight"},[(
                        _vm.order.isOrigin &&
                        _vm.origin.color_list[i] &&
                        _vm.origin.color_list[i].quantity !== color.quantity
                      )?_c('div',{staticClass:"colorListOriginTextRight pr-2"},[_vm._v(" "+_vm._s(_vm._f("makeComma")((_vm.origin.color_list[i] ? _vm.origin.color_list[i].quantity + _vm.origin.unit_sign : "")))+" ")]):_vm._e(),_c('div',{class:_vm.origin.color_list.length &&
                        _vm.colorCompare('quantity', i)
                          ? 'colorListOriginText'
                          : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(color.quantity ? color.quantity + _vm.order.unit_sign : ""))+" ")])]),_c('div',{staticClass:"requestColorBodyRight"},[(
                        _vm.order.isOrigin &&
                        _vm.origin.color_list[i] &&
                        _vm.origin.color_list[i].unit_price !== color.unit_price
                      )?_c('div',{staticClass:"colorListOriginTextRight pr-2"},[_vm._v(" "+_vm._s(_vm._f("makeComma")((_vm.origin.color_list[i] ? _vm.origin.eschamge_sign + _vm.origin.color_list[i].unit_price : "")))+" ")]):_vm._e(),_c('div',{class:_vm.origin.color_list.length &&
                        _vm.colorCompare('unit_price', i)
                          ? 'colorListOriginText'
                          : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(color.unit_price ? _vm.order.eschamge_sign + color.unit_price : ""))+" ")])]),_c('div',{staticClass:"requestColorBodyRight"},[(
                        _vm.order.isOrigin &&
                        _vm.origin.color_list[i] &&
                        _vm.origin.color_list[i].surcharge_price !==
                          color.surcharge_price
                      )?_c('div',{staticClass:"colorListOriginTextRight"},[_vm._v(" "+_vm._s(_vm._f("makeComma")((_vm.origin.color_list[i] ? _vm.origin.eschamge_sign + _vm.origin.color_list[i].surcharge_price : "")))+" ")]):_vm._e(),_c('div',{class:_vm.origin.color_list.length &&
                        _vm.colorCompare('surcharge_price', i)
                          ? 'colorListOriginText'
                          : ''},[_vm._v(" "+_vm._s(_vm._f("makeComma")(color.surcharge_price ? _vm.order.eschamge_sign + color.surcharge_price : ""))+" ")])])])])}),_c('div',{staticClass:"colorTotalGrid"},[_c('div',{staticClass:"requestColorBody font-weight-bold",staticStyle:{"width":"100%"}},[_vm._v(" TOTAL ")]),_c('div',{staticClass:"requestColorBodyRight",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.colorTotal)+" 개 ")]),_c('div',{staticClass:"requestColorBodyRight",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm._f("makeComma")((_vm.quantityTotal + _vm.order.unit_sign)))+" ")]),_c('div',{staticClass:"requestColorBodyRight",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm._f("makeComma")((_vm.order.eschamge_sign + _vm.unitTotal)))+" ")]),_c('div',{staticClass:"requestColorBodyRight",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm._f("makeComma")((_vm.order.eschamge_sign + _vm.surchargeTotal)))+" ")])])]:[_c('div',{staticClass:"orderRequestList"},[_c('div',{staticClass:"requestColorGrid"})])]],2)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }